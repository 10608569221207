module.exports = {
  firm_Type: 'Type',
  firm_Description: 'Description',
  firm_Edit: 'Edit',
  firm_CustomerServiceList: 'Customer Service List',
  firm_CustomerServiceName: 'Customer Service Name',
  firm_Enterprise: 'Enterprise Name',
  firm_Region: 'Region',
  firm_AddService: 'Add',
  firm_BusinessType: 'Business Type',
  firm_ClientManagement: 'Client Management',
  firm_CargoWareTenantIDCargoWare: 'CargoWare TenantID	CargoWare',
  firm_Formsandotherappendix: 'Forms and other appendix',
  firm_DateofIncorporation: 'Date of Incorporation',
  firm_LegalRepresentative: 'Legal Representative',
  firm_ContactFax: 'Contact Fax',
  firm_ContactAddress: 'Contact Address',
  firm_TelephoneNumber: 'Telephone Number',
  firm_ContactPerson: 'Contact Person',
  firm_ContactZipCode: 'Contact Zip Code',
  firm_ContactEmail: 'Contact Email',
  firm_EnterpriseType: 'Enterprise Type',
  firm_EnterpriseName: 'Enterprise Name',
  firm_EnterpriseCertification: 'Enterprise Certification',
  firm_EnterpriseCertificate: 'Enterprise Certificate',
  firm_Businesstermto: 'Business term to',
  firm_IDType: 'ID Type',
  firm_RegisteredCapital: 'Registered Capital',
  firm_DearcustomerWhenyouapplyBefore: 'Dear customer, when you apply for enterprise certification, please upload the sealed business license and other valid certificates of the enterprise, as well as the letter of authorization + electronic booking agreement (please ',
  firm_clicktodownload: 'click to download',
  firm_DearcustomerWhenyouapplyAfter: ', sign and seal, and mail the original to our company). If you cannot provide such documents, we will not be able to ensure that our company can serve you.',
  firm_EnterpriseCertificationList: 'Enterprise Certification List',
  firm_AddFirm: 'Add',
  firm_EnterpriseApplicationList: 'Enterprise Application List',
  firm_AdminAccount: 'Admin Account',
  firm_EnterpriseReview: 'Enterprise Review',
  firm_ReviewRecord: 'Review Record',
  firm_AuditResult: 'Audit Result',
  firm_EnterpriseInformation: 'Enterprise Information',
  firm_BindUser: 'Bind User',
  firm_EditFirmAccount: 'Edit',
  firm_NickName: 'Nick Name',
  firm_EnterpriseEmail: 'Enterprise Email',
  firm_EnterpriseAccountList: 'Enterprise Account List',
  firm_confirmwhetherunbinduser: 'Please confirm whether to unbind the user?',
  firm_confirmwhetherenableaccount: 'Please confirm whether to enable this account?',
  firm_confirmwhetherdisableaccount: 'Please confirm whether to disable this account?',
  firm_enterinviteesenterpriseemail: "Please enter the invitee's enterprise email",
  firm_enterusernamemobileemail: 'Please enter the user name / mobile / email',
  firm_enterQQofaccountowner: 'Please enter the QQ of the account owner',
  firm_enternameofaccountowner: 'Please enter the name of the account owner',
  firm_emailoftheaccountowner: 'Please enter the email of the account owner',
  firm_Pleaseselect: 'Please select',
  firm_SetRole: 'Set Role',
  firm_Contactperson: 'Contact person',
  firm_UnassignedRole: 'Unassigned Role',
  firm_AddFirmAccount: 'Add',
  firm_Name: 'Name',
  firm_InviteUsers: 'Invite Users',
  firm_AssignedRole: 'Assigned Role',
  firm_UserNameMobileEmail: 'User Name / Mobile / Email',
  firm_AscriptionFirm: 'Ascription',
  firm_SimulateLogin: 'Simulate Login',
  firm_EnterpriseAccountListOperator: 'Enterprise Account List - Operator',
  firm_EnterpriseInfo: 'Enterprise',
  firm_AskEdit: 'Edit',
  firm_IdentificationNumber: 'Identification Number',
  firm_Operation: 'Operation',
  firm_CorporateCode: 'Corporate Code(Shipper/Consignee/Notify Party)',
  firm_CorporateList: 'Corporate List.(Shipper/Consignee/Notify Party)',
  firm_CorporateInfo: 'Corporate Info.(Shipper/Consignee/Notify Party)',
  firm_CorporateInfoMaintenance: 'Corporate Info.(Shipper/Consignee/Notify Party)Maintenance',
  firm_Tip: 'Tip: The content you entered has exceeded 7 lines. Please adjust the format.',
  firm_Note: 'Note:\n1) One line supports 35 characters. If it exceeds, it will wrap automatically;\n2) Up to 7 lines of input are supported.',
  firm_BusinessManagement: 'Business Management',
  // 租户列表
  firm_Edittenant: 'Edit',
  firm_CreationTime: 'Creation Time',
  firm_CustomizedPage: 'Customized Page',
  firm_UpdateTime: 'Update Time',
  firm_SystemId: 'System Id',
  firm_Add: 'Add',
  firm_Addtenant: 'Add',
  firm_Domain: 'Domain',
  firm_TenantList: 'Tenant List'
}


module.exports = {
  analysis_Currency: '币种',
  analysis_ProductType: '产品类型',
  analysis_Region: '航线',
  analysis_CustomerBehavior: '客户行为',
  analysis_DailyQueryUsers: `每日查询用户`,
  analysis_DailyVisitedUsers: '每日到访用户',
  analysis_DailyPublishedSKUs: '每日发布SKU',
  analysis_DailyTransactionAmount: '每日交易金额',
  analysis_DailyTransactionQuantity: '每日交易数量',
  analysis_DailyOrderClients: '每日下单客户',
  analysis_DailyRegisterOrLoginUsers: '每日注册或登录用户',
  analysis_Date: '日期',
  analysis_AuditStatuss: '审核状态',
  analysis_DataAnalysis: '数据分析',
  analysis_CustomerType: '用户类型',
  analysis_Userbehavior: '用户行为'
}

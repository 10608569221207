module.exports = {
  order_SOP: 'SOP',
  order_Remarks: 'Remarks',
  order_Tracking: 'Tracking',
  order_Time: 'Time',
  order_Status: 'Status',
  order_JobNo: 'Job No.',
  order_Details: 'Details',
  order_Withdraw: 'Withdraw',
  order_Carrier: 'Carrier',
  order_Pending: 'Pending',
  order_Today: 'Today',
  order_OrderNumber: 'Order Number',
  order_OrderType: 'Order Type',
  order_OrderList: 'Order List',
  order_Search: 'Search',
  order_Customer: 'Customer',
  order_Pleaseconfirmtowithdrawthisspecialapplication: 'Please confirm to withdraw this special application?',
  order_Reviewer: 'Reviewer',
  order_AllOrders: 'All Orders',
  order_Submitter: 'Submitter',
  order_Thismonth: 'This month',
  order_BookingDate: 'Booking Date',
  order_NextWeek: 'Next Week',
  order_Approved: 'Approved',
  order_Rejected: 'Rejected',
  order_ETD: 'ETD',
  order_Reapply: 'Reapply',
  order_Reset: 'Reset',
  order_Customdisplaycolumns: 'Custom display columns',
  order_OperationDepartment: 'Operation Department',
  order_OperationBranch: 'Operation Branch',
  order_Operator: 'Operator',
  order_Vessel: 'Vessel',
  order_OrderDetails: 'Order Details',
  order_CostList: 'Cost List',
  order_Port: 'Port',
  order_Voyage: 'Voyage',
  order_CommodityInformation: 'Commodity Information',
  order_SICutOff: 'SI Cut-Off',
  order_CYCutoff: 'CY Cut-off',
  order_ClosingTime: 'Closing Time',
  order_CustomerService: 'Customer Service',
  order_ContactInformation: 'Contact Information',
  order_BookingInformation: 'Booking Information',
  order_Review: 'Review',
  order_Reason: 'Reason',
  order_Auditinformation: 'Audit information',
  order_BillofladingNo: 'Bill of lading No.',
  order_Billofladinginformation: 'Bill of lading information',
  order_entrustRemarks: 'Remarks',
  order_Document: 'Document',
  order_BoxInformation: 'Box Information',
  order_SalesDepartment: 'Sales Department',
  order_SalesBranch: 'Sales Branch',
  order_Salesman: 'Salesman',
  order_SerialNumber: 'Serial Number',
  // 委托下单
  order_Forgeneralchemicals: 'For general chemicals, the territorial identification certificate shall be uploaded (for example, the identification certificate of the chemical institute shall be uploaded for shipment from Shanghai port), for dangerous goods, the hazard report certificate shall be uploaded, and MSDS shall be attached.',
  order_PackageCategory: 'Package Category',
  order_Template: 'Template',
  order_BookingDetails: 'Details',
  order_SecondNotifyParty: 'Second Notify Party',
  order_OrderNoXXX1: 'Order No.: ',
  order_OrderNoXXX2: 'The order has been submitted successfully. Please wait for confirmation. You can find this shipping order in workbench',
  order_OrderNoXXX3: ' - > booking form, or click >',
  order_OrderNoXXX4: '< paste the order number into the query interface to search it.',
  order_Shipper: 'Shipper',
  order_Surcharge: 'Surcharge',
  order_OceanFreight: 'Ocean Freight',
  order_Total: 'Total',
  order_BasicInformation: 'Basic Information',
  order_MarksNumbers: 'Marks & Numbers',
  order_Clear: 'Clear',
  order_PleasedragfileofShippingOrderXXX: 'Please drag the file of Shipping Order here to upload, or click here to uploa',
  order_RecommendPNGJPGXXX: 'Recommend: .PNG, .JPG, .GIF, .TIF, .Doc, .Docx, .XLS, .Xlsx, .Pdf',
  order_Pleaseuploadrelevantdocuments: 'Please upload relevant documents',
  order_ShipperConsigneeNotifyParty: 'Shipper/Consignee/Notify Party',
  order_Consignee: 'Consignee',
  order_FillinanycontentyouthinknecessaryXXX: 'Fill in any content you think necessary to explain to the entrusting party, so that the entrusting party can understand your requirements correctly during operation and communication.',
  order_NotifyParty: 'Notify Party',
  order_SAMEASCONSIGNEE: 'SAME AS CONSIGNEE',
  order_Booking: 'Booking',
  order_TSBookingInformation: 'Booking  Information',
  order_Fileupload: 'File upload',
  order_OrdersubmittedsuccefullyWaitingforconfirmation: 'Order submitted succefully, Waiting for confirmation.',
  order_RelevantDocuments: 'Relevant Documents',
  order_Subtotal: 'Subtotal',
  order_Choosefrom: 'Choose from',
  order_BusniessRefNo: 'Busniess Ref No.',
  order_BusinessRelevantInformation: 'Business Relevant Information',
  order_Bargaining: 'Bargaining',
  order_RecommendPDFRarZip: 'Recommend: .PNG, .JPG, .TXT, .Doc, .Docx, .XLS, .Xlsx, .PDF, .Rar, .Zip',
  order_ToOrder: 'To Order',
  order_RedoUploadRedoRecognition: 'Redo Upload， Redo Recognition',
  order_Thetotalsizeoffilesarenomorethan50MB: 'The total size of files are no more than 50MB.'
}

import request from '../request'
import url from '../url'

export function login(data){
    return request({
        url: url.user.login,
        method: 'post',
        data
    })
}

export function mobileSendSms(params) {
    return request({
        url: url.user.mobileSendSms,
        method: 'get',
        params
    })
}
  
export function mobileCommit(data) {
    return request({
        url: url.user.mobileCommit,
        method: 'post',
        data
    })
}

// 找回密码-短信获取验证码接口
export function resetGetCode(params) {
    return request({
        url: url.user.resetGetCode,
        method: 'get',
        params
    })
}
// 找回密码-验证验证码接口
export function resetCommitCode(data) {
    return request({
        url: url.user.resetCommitCode,
        method: 'post',
        data
    })
}
// 找回密码-重置密码接口
export function resetPassword(data) {
    return request({
        url: url.user.resetPassword,
        method: 'post',
        data
    })
}

module.exports = {
  personal_Mobile: '手机',
  personal_BindMobile: '绑定手机',
  personal_BindEmail: '绑定邮箱',
  personal_BindPersonalMobile: '绑定个人手机',
  personal_BindPersonalEmail: '绑定个人邮箱',
  personal_Unbind: '解除绑定',
  personal_SetMobile: '设置手机',
  personal_SetEmail: '设置邮箱',
  personal_Save: '保存',
  personal_Back: '返回',
  personal_Personal: '个人',
  personal_Profile: '个人资料',
  personal_Ascription: '归属',
  personal_GetVerificationCode: '获取验证码',
  personal_BonusPoints: '积分',
  personal_Type: '类型',
  personal_Offline: '离线',
  personal_Password: '密码',
  personal_ConfirmPassword: '密码确认',
  personal_Passwordmodifysuccessfullypleaseloginwithnewpassword: '密码修改成功，请使用新密码登录。',
  personal_NickName: '中文名字',
  personal_Switch: '切换',
  personal_ConfirmUnbindAccountBefore: '请确认是否解除账号',
  personal_ConfirmUnbindAccountAfter: '的绑定？',
  personal_ConfirmActivateAsYourWorkaccountBefore: '请确认是否切换',
  personal_ConfirmActivateAsYourWorkaccountAfter: '作为您的工作账号？',
  personal_Pleaseenteryourmobile: '请输入您的手机',
  personal_Pleaseenteryournewmobile: '请输入您的新手机',
  personal_Pleaseenteryournewemail: '请输入您的新邮箱',
  personal_Pleaseenteryouremail: '请输入您的邮箱',
  personal_Pleaseentertheenterpriseinvitationcode: '请输入企业给你的邀请码',
  personal_Pleaseenterthecurrentpassword: '请输入原密码',
  personal_Pleaseenterthenewpassword: '请输入新密码',
  personal_Pleaseenterthepasswordagain: '请再次输入密码',
  personal_Pleaseenterverificationcode: '请输入验证码',
  personal_OK: '好的',
  personal_Comfirm: '确认',
  personal_Cancel: '取消',
  personal_Prev: '上一步',
  personal_Next: '下一步',
  personal_SetResults: '设置结果',
  personal_Bindmobilesuccessfully: '手机绑定成功。',
  personal_EnterEnterpriseInvitationCode: '输入企业邀请码',
  personal_NewPassword: '新密码',
  personal_NewMobile: '新手机',
  personal_NewEmail: '新邮箱',
  personal_Edit: '修改',
  personal_ModifyPassword: '修改密码',
  personal_VerifyMobile: '验证手机',
  personal_VerifyEmail: '验证邮箱',
  personal_InvitationCode: '邀请码',
  personal_UserName: '用户名',
  personal_Bindemailsuccessfully: '邮箱绑定成功。',
  personal_Valid: '有效',
  personal_CurrentPassword: '原密码',
  personal_Online: '在线',
  personal_Account: '账号',
  personal_Relogin: '重新登陆',
  // 个性化配置
  personal_BackgroundPicture: '背景图',
  personal_BackgroundPicturePlaySetting: '背景图播放',
  personal_FloorPrice: '底价显示价格',
  personal_ConnectFMS: '对接FMS',
  personal_ServiceAgreement: '服务协议',
  personal_OperatorPersonalization: '个性化配置',
  personal_FCLDefaultDisplay: '海运整箱默认显示筛选条件',
  personal_DefaultOrderDisplay: '默认订单展示时间',
  personal_Isbargainingallowed: '是否允许在线议价',
  personal_Picturelength45: '图片长宽尺寸：105*45',
  personal_Picturelength1080: '图片长宽尺寸：1920*1080',
  personal_OnlyJPGPNGnomorethan1m: '只支持JPG、PNG，每张大小不超过1M。',
  personal_SiteName: '网站名称',
  personal_Domain: '网站域名',
  personal_Systemallowedupto3pictures: '系统最大支持3张背景图',
  personal_BusinessFlow: '业务流向',
  personal_PrivacyAgreement: '隐私协议',
  personal_ThemeColor: '主题颜色',
  // 忘记密码
  personal_LoginPassword: '登录密码',
  personal_Passwordresetsuccessfullypleaseloginwithnewpassword: '密码重置成功，请使用新密码登录。',
  personal_Pleaseentertheloginpassword: '请输入登录密码',
  personal_MobileRetrieve: '手机找回',
  personal_LoginNow: '现在登录',
  personal_VerificationCode: '验证码',
  personal_EmailRetrieve: '邮箱找回',
  personal_RetrievalMethod: '找回方式',
  personal_RetrieveResults: '找回结果',
  personal_ResetPassword: '重置密码'
}

const baseUrl = '/gateway'
const url = {
    basicData: {
        getCargoSeaPort: baseUrl + '/booking/public/api/basic/getCargoSeaPort', //交货地-收货地地址查询
        getTenantList: baseUrl + '/omc/company/getCompanyListNotInTenantId', // 查询服务商
    },
    role:{
        getPersonalized: baseUrl + '/b2b/public/api/companyIndividuation/selectCompanyIndividuationByWL', // 获取个性化配置沃联
    },
    user: {
        login: baseUrl + '/b2b/user/public/api/login', //登录
        logout: baseUrl + '/b2b/user/public/api/logout',
        mobileSendSms: baseUrl + '/b2b/user/public/api/register/verifyUserSendMsg', // 发送手机验证码
        mobileCommit: baseUrl + '/b2b/user/public/api/register/userVerifyCode', // 手机号或邮箱注册,
        resetGetCode: baseUrl + '/b2b/user/public/api/reset/password/VerifyUserSendMsg', // 找回密码-发送验证码
        resetCommitCode: baseUrl + '/b2b/user/public/api/reset/password/VerifyCode', // 找回密码-验证验证码
        resetPassword: baseUrl + '/b2b/user/public/api/reset/password/setPwd', // 重新设置密码
    },
    bookingQuery: {
        FCLListNoPagination: baseUrl + '/booking/price/fcl/getFclPriceWT', // 运价列表查询不分页
        surchargeFclListApp: baseUrl + '/booking/price/fcl/surchargeFclListApp', // 查询附加费
        listSearchHistory: baseUrl + '/booking/priceSearchHistory/listSearchHistory', //查询运价列表历史
        addSearchHistory: baseUrl + '/booking/priceSearchHistory/addSearchHistory', // 添加运价列表历史
    },
}

export default url
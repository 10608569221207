
import { getToken, setToken, removeToken } from '@/utils/auth'
import { login } from '../../api/modules/user'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: {}
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USERINFO: (state,userInfo) => {
    state.userInfo = userInfo
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
  },
  REMOVE_TOKEN: (state) => {
    state.userInfo = {}
    removeToken()
  },
}

const actions = {
  loginAction({ commit }, userInfo){
    const { key, password } = userInfo
    return new Promise((resolve,reject) => {
      login({key: key.trim(), password: password}).then(response => {
        const { token, content } = response
        commit('SET_USERINFO', content.detail)
        commit('SET_TOKEN', token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetToken({ commit }) {
    return new Promise(() => {
      commit('REMOVE_TOKEN')
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


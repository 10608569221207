module.exports = {
  price_fcl: 'FCL',
  price_whetherDirect: 'whether direct',
  price_direct: 'Direct',
  price_nonDirect: 'Non-direct',
  price_priceRanking: 'Price Ranking',
  price_lowToHigh: 'Low to High',
  price_highToLow: 'High to Low',
  price_advantage: 'Strength Compare',
  price_advantageTitle: 'Click  to start compare',
  price_dayArrival: 'Days',
  price_serviceEvaluation: 'Service Evaluation',
  price_freightDetails: 'Freight Details',
  price_carrier: 'Carrier',
  price_days: 'ETD',
  price_volume: 'Cargo Volume',
  price_proxyCompanies: 'Agent',
  price_costDescription: 'Charge Description',
  price_supportServices: 'Supporting Service',
  price_customsService: 'Customs Clearance Service',
  price_vehicleTowing: 'Trucking Service',
  price_trailerPriceTitle: 'Trailer prices subject to quotation',
  price_storageService: 'Warehousing Service',
  price_rateReference: 'Exchange Rate Reference',
  price_priceTransRmb: 'Converted CNY price',
  price_operationalMatters: 'Operations',
  price_bookingConfirm: 'Confirm Booking',
  price_timeDelivery: 'Shipping Date',
  price_chargeStandard: 'Destination Port Charge Tariff',
  price_reminder: 'Tips',
  price_reminder2: 'Hello, if you need online booking service, and aquire more favorable freight rates, please click here',
  price_reminder3: 'Apply for enterprise users',
  price_reminder4: 'Enterprise info is under verification,  thanks for your patience!',
  price_choiceRate: 'Select Rate',
  price_fillOrderInfo: 'Fill in and verify order information',
  price_successSubmitOrder: 'Order submitted successfully!',
  price_historyRecord: 'History Record',
  price_selectName: 'Select Product Name',
  price_use: 'Use',
  price_cargoQuantity: 'Goods Quantity',
  price_cargoWeight: 'Cargo Weight',
  price_cargoVolume: 'Cargo Volume',
  price_freightTerms: 'Freight Term',
  price_placeDelivery: 'Place of Delivery',
  price_addedServices: 'Service options',
  price_agreed: 'I have read and agreed',
  price_btn_confirmSubmitOrder: 'Confirm and submit order',
  price_submiteCompleteAlert: 'You are entering booking info. Are you sure to exit?',
  price_selectRecord: 'Please select a record',
  price_billAddrDialog: 'Choose from history record',
  price_portDestination: 'Destination Port Charge',
  price_bookingApplicaSuccessMsg: 'Congratulations! Your booking order is accepted. The booking number is ',
  price_bookingAirMsg2: 'Congratulations! Your delegate has been accepted. The delegate number is',
  // price_myDeskSee:'You can log on to my desk_',
  price_labelTimer: 'Return after seconds',
  price_surcharge: 'Surcharge',
  price_extraInfo: 'Detailed Info',
  price_deadline: 'Cut off',
  // price_validity:'Validity',
  price_dockArea: 'Port of Call',
  price_weightLimit: 'Weight Limit Information',
  price_serviceProvider: 'Service Provider',
  price_evaluationDetails: 'Comment Details',
  price_lineEvalua: 'Line Evaluation',
  price_evaluationScore: 'Evaluation Score',
  price_share: 'Total',
  price_humanEvaluation: 'Evaluations',
  price_priceDdvantage: 'Rate Advantages',
  price_shipmentTime: 'On Time Performance',
  price_bookingRate: 'Booking Speed',
  price_billService: 'Bill of Lading Service',
  price_throwBox: 'Shut out frequency',
  price_second: 'Times',
  // ----------------portal-------------------',
  price_derivedWord: 'Export Word',
  price_derivedPdf: 'Export PDF',
  price_paymentMethod: 'Payment Method',
  price_PlacePayment: 'Payment Place',
  price_BLNumber: 'Copy Number',
  price_agentPartyLine: 'Agent Information',
  price_signPlaceBL: 'Issue Place',
  price_signDateBL: 'Issue Date',
  price_packing: 'Package Type',
  price_insideQuantity: 'Number of Pieces',
  price_docPacking: 'Piece package',
  // price_dischargingPort:'Port of Discharge',
  // price_destinationPort:'Place of delivery',
  price_containerNo: 'Container Number Seal Number',
  price_quantityUpper: 'Number Capitalized',
  price_error: 'Error',
  price_splitSingle: 'Separate Bill',
  price_andSingle: 'Combine Bill',
  price_airline: 'Airline',
  price_flight: 'Flight'
}

module.exports = {
  100000: 'The {0} column data is too long',
  100001: 'User name is empty',
  100002: 'Password is empty',
  110000: 'Unable to obtain enterprise information',
  200000: 'System abnormal, pls contact your system admin',
  210000: 'Too long, please login again',
  220000: 'Not granted the appropriate permissions',
  // MemberController',
  300001: 'Pls try again in one minute',
  300002: 'The mobile phone number already exists',
  300003: 'Verification code is wrong',
  300004: 'SMS verification code is wrong',
  300005: 'User name or password is wrong',
  300006: 'The account does not exist',
  300007: 'The account is disabled',
  300008: 'Abnormal login, pls contact your system admin',
  300009: 'E-mail already exists',
  300010: 'Username already exists',
  300011: 'User registration failed',
  310000: 'Unable to get current user\'s enterprise information',
  310001: 'Fail to generate enterprise information',
  310002: 'Fail to create a user, the same user\'s information already exists.',
  310003: 'Fail to generate a partner',
  310004: 'Enterprise name already exists',
  310005: 'Save failed,  {0} save error',
  310008: 'Pls add contact information',
  310009: 'Sorry, your registered identity information already exists',
  310010: 'Remark cannot be empty',
  320000: 'mistake',
  320001: 'invalid order',
  320002: 'The order for non audit platform, please check in Cargoware',
  330000: 'Abnormal partner association',
  330001: 'The order does not exist',
  330002: 'Order version mismatch',
  330003: 'Fail to modify SOP',
  340000: 'FCL freight advertising information does not exist',
  350000: 'Delete User Information Error',
  350001: 'Unable to delete the current logged-on user',
  350002: 'Update user status Error',
  360000: 'Single confirmed wrong',
  360001: 'Delete role error',
  360002: 'The user does not have permission to booking',
  370000: 'Set the price level error',
  370001: 'Get the price level error',
  400001: 'Unable to get related data, pls contact system admin',
  400002: 'Returning data is incorrect, pls contact your system admin',
  400003: 'The data already exist, pls input again',
  400005: 'Save Template Error',
  400006: 'Select Template Error',
  400007: 'Delete Template Error',
  // OrderController',
  400010: 'Save boxing information failed'
}

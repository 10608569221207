module.exports = {
  firm_Type: '节点类型',
  firm_Description: '节点说明',
  firm_Edit: '编辑客服',
  firm_CustomerServiceList: '客服列表',
  firm_CustomerServiceName: '客服姓名',
  firm_Enterprise: '企业',
  firm_Region: '区域',
  firm_AddService: '新增客服',
  firm_BusinessType: '业务类型',
  firm_ClientManagement: '客户管理',
  firm_CargoWareTenantIDCargoWare: '客户编号',
  firm_Formsandotherappendix: '表格及其他附件',
  firm_DateofIncorporation: '成立日期',
  firm_LegalRepresentative: '法定代表人',
  firm_ContactFax: '联络传真',
  firm_ContactAddress: '联络地址',
  firm_TelephoneNumber: '联络电话',
  firm_ContactPerson: '联络人',
  firm_ContactZipCode: '联络邮编',
  firm_ContactEmail: '联络邮箱',
  firm_EnterpriseType: '企业类型',
  firm_EnterpriseName: '企业名称',
  firm_EnterpriseCertification: '企业认证',
  firm_EnterpriseCertificate: '企业证件',
  firm_Businesstermto: '营业期限至',
  firm_IDType: '证件类型',
  firm_RegisteredCapital: '注册资本',
  firm_DearcustomerWhenyouapplyBefore: '尊敬的客户您好，在您申请企业认证时，请上传盖章的营业执照等企业有效证件， 及授权书+电子订舱协议（请',
  firm_clicktodownload: '点击下载',
  firm_DearcustomerWhenyouapplyAfter: '签署并盖章，正本邮寄至我司），如无法提供此类文件将无法确保我司能为您服务。',
  firm_EnterpriseCertificationList: '企业认证申请列表',
  firm_AddFirm: '申请企业认证',
  firm_EnterpriseApplicationList: '企业申请列表',
  firm_AdminAccount: '管理员账号',
  firm_EnterpriseReview: '企业审核',
  firm_ReviewRecord: '审核记录',
  firm_AuditResult: '审核结果',
  firm_EnterpriseInformation: '企业信息',
  firm_BindUser: '绑定用户',
  firm_EditFirmAccount: '编辑企业账号',
  firm_NickName: '中文名字',
  firm_EnterpriseEmail: '企业邮箱',
  firm_EnterpriseAccountList: '企业账号',
  firm_confirmwhetherunbinduser: '请确认是否解绑该用户？',
  firm_confirmwhetherenableaccount: '请确认是否启用该账号？',
  firm_confirmwhetherdisableaccount: '请确认是否停用该账号？',
  firm_enterinviteesenterpriseemail: '请输入受邀者的企业邮箱',
  firm_enterusernamemobileemail: '请输入用户名/手机/邮箱',
  firm_enterQQofaccountowner: '请输入账号所有人的QQ',
  firm_enternameofaccountowner: '请输入账号所有人的姓名',
  firm_emailoftheaccountowner: '请输入账号所有人的邮箱',
  firm_Pleaseselect: '请选择',
  firm_SetRole: '设置角色',
  firm_Contactperson: '是否联络人',
  firm_UnassignedRole: '未分配角色',
  firm_AddFirmAccount: '新增企业账号',
  firm_Name: '姓名',
  firm_InviteUsers: '邀请用户',
  firm_AssignedRole: '已分配角色',
  firm_UserNameMobileEmail: '用户名/手机/邮箱',
  firm_AscriptionFirm: '归属企业',
  firm_SimulateLogin: '模拟登录',
  firm_EnterpriseAccountListOperator: '企业账号列表-运营',
  firm_EnterpriseInfo: '企业资料',
  firm_AskEdit: '申请修改',
  firm_IdentificationNumber: '证件号码',
  firm_Operation: '操作',
  firm_CorporateCode: '收发通企业代码',
  firm_CorporateList: '收发通企业列表',
  firm_CorporateInfo: '收发通企业信息',
  firm_CorporateInfoMaintenance: '收发通企业信息维护',
  firm_Tip: '提示：您输入的内容已超出7行，请调整格式。',
  firm_Note: '注：\n1）一行支持35个字符，超过将自动换行。\n2）最多支持输入7行内容。',
  firm_BusinessManagement: '业务管理',
  // 租户列表
  firm_Edittenant: '编辑租户',
  firm_CreationTime: '创建日期',
  firm_CustomizedPage: '个性化页面',
  firm_UpdateTime: '更新日期',
  firm_SystemId: '系统识别码',
  firm_Add: '新增',
  firm_Addtenant: '新增租户',
  firm_Domain: '域名',
  firm_TenantList: '租户列'
}

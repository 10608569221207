module.exports = {
  price_fcl: '海运整箱',
  price_whetherDirect: '是否直达',
  price_direct: '直达',
  price_nonDirect: '非直达',
  price_priceRanking: '价格排序',
  price_lowToHigh: '从低到高',
  price_highToLow: '从高到低',
  price_advantage: '比优势',
  price_advantageTitle: '点击开始进行对比',
  price_dayArrival: '天',
  price_serviceEvaluation: '服务评价',
  price_freightDetails: '运价详情',
  price_carrier: '船公司',
  price_days: '船期',
  price_volume: '货量',
  price_proxyCompanies: '代理公司',
  price_costDescription: '费用说明',
  price_supportServices: '配套服务',
  price_customsService: '报关服务',
  price_vehicleTowing: '拖车服务',
  price_trailerPriceTitle: '拖车价格以实际为准',
  price_storageService: '仓储服务',
  price_rateReference: '汇率参考',
  price_priceTransRmb: '人民币折合价',
  price_operationalMatters: '操作事项',
  price_bookingConfirm: '确认订舱',
  price_timeDelivery: '出运日期',
  price_chargeStandard: '目的港收费标准',
  price_reminder: '温馨提示',
  price_reminder2: '您好，如需线上订舱服务，并取得更优惠的运价，请点击',
  price_reminder3: '申请成为企业用户',
  price_reminder4: '进行企业资料的认证及审核，非常感谢您的耐心配合！',
  price_choiceRate: '选择运价',
  price_fillOrderInfo: '填写并核实订单信息',
  price_successSubmitOrder: '成功提交订单',
  price_historyRecord: '历史记录',
  price_selectName: '选择品名',
  price_use: '使用',
  price_cargoQuantity: '货物数量',
  price_cargoWeight: '货物重量',
  price_cargoVolume: '货物体积',
  price_freightTerms: '运费条款',
  price_placeDelivery: '交货地',
  price_addedServices: '服务选项',
  price_agreed: '我已经阅读并同意',
  price_btn_confirmSubmitOrder: '确认并提交订单',
  price_submiteCompleteAlert: '您正在录入订舱数据，确定要退出？',
  price_selectRecord: '请选择一条记录',
  price_billAddrDialog: '选择历史收发通信息',
  price_portDestination: '目的港收费',
  price_bookingApplicaSuccessMsg: '恭喜！您的订舱订单申请成功，订舱编号为',
  // price_myDeskSee:'您可以登陆到我的工作台查看',
  price_bookingAirMsg2: '恭喜！您的委托已受理，委托编号为',
  price_labelTimer: '秒后返回',
  price_surcharge: '附加费',
  price_extraInfo: '详细信息',
  price_deadline: '截关',
  // price_validity:'有效期',
  price_dockArea: '靠挂码头',
  price_weightLimit: '限重信息',
  price_serviceProvider: '服务提供商',
  price_evaluationDetails: '评价详情',
  price_lineEvalua: '线路评价',
  price_evaluationScore: '评价得分',
  price_share: '共有',
  price_humanEvaluation: '人评价',
  price_priceDdvantage: '运价优势',
  price_shipmentTime: '船期准点',
  price_bookingRate: '订舱速度',
  price_billService: '提单服务',
  price_throwBox: '甩箱次数',
  price_second: '次',
  // ----------------portal-------------------
  price_derivedWord: '导出Word',
  price_derivedPdf: '导出PDF',
  price_paymentMethod: '付款方式',
  price_PlacePayment: '付款地点',
  price_BLNumber: '份数',
  price_agentPartyLine: '代理信息',
  price_signPlaceBL: '签发地点',
  price_signDateBL: '签发日期',
  price_packing: '包装',
  price_insideQuantity: '小件数',
  price_docPacking: '小件数包装',
  // price_dischargingPort:'卸货港',
  // price_destinationPort:'交货地',
  price_containerNo: '箱号封号',
  price_quantityUpper: '件数大写',
  price_error: '出错啦',
  price_splitSingle: '拆单',
  price_andSingle: '并单',
  price_airline: '航空公司',
  price_flight: '航班'
}

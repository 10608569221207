const ErrorMessage = require('./errorMessage')
const Message = require('./message')
const Price = require('./price')
const Booking = require('./booking')
const Role = require('./role')
const Common = require('./common')
const Order = require('./order')
const Personal = require('./personal')
const Firm = require('./firm')
const Analysis = require('./analysis')
const Spa = require('./spa')
const Py = require('./pinyin')

module.exports = {
  ErrorMessage,
  Message,
  Price,
  Booking,
  Role,
  Common,
  Order,
  Personal,
  Firm,
  Analysis,
  Spa,
  Py
}

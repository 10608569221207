
module.exports = {
  analysis_Currency: 'Currency',
  analysis_ProductType: 'Product Type',
  analysis_Region: 'Region',
  analysis_CustomerBehavior: 'Customer Behavior',
  analysis_DailyQueryUsers: 'Daily Query Users',
  analysis_DailyVisitedUsers: 'Daily Visited Users',
  analysis_DailyPublishedSKUs: 'Daily Published SKUs',
  analysis_DailyTransactionAmount: 'Daily Transaction Amount',
  analysis_DailyTransactionQuantity: 'Daily Transaction Quantity',
  analysis_DailyOrderClients: 'Daily Order Clients',
  analysis_DailyRegisterOrLoginUsers: 'Daily Register Or Login Users',
  analysis_Date: 'Date',
  analysis_AuditStatuss: 'Audit Statuss',
  analysis_DataAnalysis: 'Data Analysis',
  analysis_CustomerType: 'Customer Type',
  analysis_Userbehavior: 'User behavior'
}

module.exports = {
  py_denglu: 'Login',
  py_zhongwenmincheng: '中文名字',
  py_yingwenmincheng: '英文名称',
  py_shuruqiyeyunyingyaoqingma: '输入企业/运营邀请码',
  py_wodeqiye: '我的企业',
  py_qiyezhanghu: '企业账户',
  py_quedin: '确定',
  py_zhichishuangjizhankaitianxie: '填写',
  py_fahuodi: '发货地',
  py_guanyulitongwuliu: '关于利通物流',
  py_kefuxingming: '客服姓名',
  py_qiyeshenqingliebiao: '企业申请列表',
  py_shoufatongqiye: '收发通企业',
  py_kehuqiyexinxi: '客户企业信息',
  py_qingshurushoufatongdaima: '请输入收发通企业代码',
  py_qingshurushoufatongxinxi: '请输入收发通企业信息',
  py_xuanzeshoufatongqiyexinxi: '选择收发通企业信息',
  py_qiyeguanli: '企业管理',
  py_shenghexiangqing: '审核详情',
  py_yunyingguanli: '运营管理',
  py_quanxianguanli: '权限管理',
  py_danhaopeizhi: '单号配置',
  py_danhaopeizhichenggong: '单号配置成功',
  py_danhaopeizhishibai: '单号配置失败',
  py_juesexiangqing: '角色详情',
  py_wodedingdan: '我的订单',
  py_qiyeguanliyuanjieshoushenhejieguodeyouxiang: '企业管理员接收审核结果的邮箱',
  py_xuyao: '需要',
  py_buxuyao: '不需要',
  py_yewumokuai: '业务模块',
  py_CargeWareyuanchengdincang: 'CargoWare远程订舱',
  py_CargoWaredincangyushen: 'CargoWare订舱预审',
  py_sanshitiannei: '30天内',
  py_liushitiannei: '60天内',
  py_jiushitiannei: '90天内',
  py_yibaibashitiannei: '180天内',
  py_sanbailiushitiannei: '360天内',
  py_danye: '单页',
  py_butongyi: '不同意',
  py_bili: '比例',
  py_jine: '金额',
  py_feiyongmingcheng: '费用名称',
  py_qiyemingcheng: 'Enterprise Name',
  py_tian: 'Days',
  py_hangcheng: 'Voyage(Day)',
  py_shurudanhaokuaisutiaozhaun: '输入单号快速跳转',
  py_qingzaiciquerenmima: 'Please confirm the password again',
  py_qiyeliebiao: 'Corporate List',
  py_ruxuxianshangdingcangfuwuXXX: '您好, 如需线上订舱服务, 并取得更优惠的运价, 请点击确认进行企业资料的认证及审核, 非常感谢您的耐心配合!',
  py_wenxintishi: '温馨提示',
  py_guanbi: '关闭',
  py_dulizhan: '独立站',
  py_wolianzhijia: '沃联之家',
  py_CargoWare: 'CargoWare',
  py_kehulaiyuan: '客户来源'

}

module.exports = {
  spa_SKURate: 'SKU Rate',
  spa_PackageQuantity: 'Package Quantity',
  spa_ProductInformation: 'Product Information',
  spa_SuccessfullySubmittedApplication: 'Successfully Submitted Application',
  spa_Applicationsubmittedsuccessfully1: 'Application submitted successfully!',
  spa_Applicationsubmittedsuccessfully2: 'Congratulations! Your special offer application has been successfully submitted with',
  spa_Applicationsubmittedsuccessfully3: 'It will automatically return to the freight list after 3 seconds.',
  spa_FreightList: 'Freight List',
  spa_ShippingScheduleInformation: 'Shipping Schedule Information',
  spa_No: 'No',
  spa_Yes: 'Yes',
  spa_LaneCode: 'Lane Code',
  spa_Notes: 'Notes',
  spa_ContactPerson: 'Contact Person',
  spa_GrossWeight: 'Gross Weight',
  spa_Commodity: 'Commodity',
  spa_ApplicationRemarks: 'Application Remarks',
  spa_ApplicationNo: 'Application No.',
  spa_RequestRate: 'Request Rate',
  spa_ReviewRate: 'Review Rate',
  spa_Rejected: 'Rejected',
  spa_Approved: 'Approved',
  spa_DefaultBusinessContact: 'Default Business Contact',
  spa_AllSpecialRateRequests: 'All Special Rate Requests',
  spa_SpecialRate: 'Special Rate',
  spa_SpecialRateApplicationPrice: 'Special Rate Application Price',
  spa_Tips: 'Tips：Rate adjustment，Special Rate Request has been adjusted.',
  spa_Volume: 'Volume',
  spa_Add: 'Add',
  spa_Order: 'Order',
  spa_ContainersDescription: 'Containers Description',
  spa_Remove: 'Remove',
  spa_EnglishDescription: 'English Description',
  spa_OriginalRateReference: 'Original Rate Reference',
  spa_TransportationMode: 'Transportation Mode',
  spa_NativeDescription: 'Native Description',
  spa_Notifier: 'Notify Party'
}

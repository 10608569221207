module.exports = {
  messages_sunday: 'SUN.',
  messages_monday: 'MON.',
  messages_tuesday: 'TUE.',
  messages_wensday: 'WED.',
  messages_thursday: 'THU.',
  messages_friday: 'FRI.',
  messages_saturday: 'SAT.',
  messages_productService: 'PRODUCT SERVICE',
  messages_booking: 'Booking',
  messages_enterpriseUsers: 'Welcome, Enterprise user!',
  messages_welcome: 'Welcome',
  messages_login: 'Log In',
  messages_signOut: 'Log Out',
  messages_onlineBooking: 'E-BUSINESS',
  messages_myDesk: 'My Workbench',
  messages_inputUnno: 'Please enter UNNO',
  messages_queryFclPol: 'Please enter a departure port',
  messages_queryFclPod: 'Please enter a destination',
  messages_newsInfo: 'News',
  messages_promotionInfo: 'Promotion information',
  messages_industryDynamics: 'Industry news',
  messages_logisticsBusiness: 'Comprehensive Logistics Business',
  messages_tracing: 'Cargo Tracking',
  messages_portOfShipment: 'Port of Loading',
  messages_portOfDestination: 'Port of Destination',
  messages_placeReceipt: 'Place Receipt',
  messages_portOfDischarge: 'Port of Discharge',
  messages_polCode: 'POL',
  messages_podCode: 'POD',
  messages_exportPrice: 'Export Price',
  messages_hotSearch: 'Hot Search',
  messages_hamburger: 'Hamburg',
  messages_busan: 'Busan',
  messages_losAngeles: 'Los Angeles',
  messages_jobNo: 'Job Number',
  messages_priceNo: 'Rate Number',
  messages_priceCompare: 'Rate Comparison',
  messages_headerBooking: 'BOOKING',
  messages_headerPrAlert: 'PR-ALERT',
  messages_headerTracking: 'TRACKING',
  messages_rememberState: 'Remember login status',
  messages_forgetPassword: 'Forgot password?',
  messages_newUserTitle: 'You are a new user',
  messages_mailboxCellPhone: 'Email / mobile phone',
  messages_inputPassword: 'Please enter the password again',
  messages_inputPassword2: 'Please enter the confirmation password',
  messages_inputPassword3: 'Two passwords do not match, please re-enter',
  messages_inputPassword4: 'The password can\'t be less than 6 bits',
  messages_inputPassword5: 'Password must be 6-20 characters, including numbers, uppercase and lowercase letters',
  messages_afreshObtainCaptcha: 'To reacquire the verification code',
  messages_smsVerificationCode: 'SMS verification code',
  messages_getSmsVerificationCode: 'Get SMS verification code',
  messages_readAgreement: 'I have read and accept it ',
  messages_setLoginPassword: 'Set login password',
  messages_4SMS: 'Please enter your 4-digit SMS verification code',
  messages_mobile: 'Mobile',
  messages_inputMobile: 'Please enter phone number',
  messages_sendSuccess: 'Sent successfully',
  messages_sendError: 'Sent failed',
  messages_reSend: 'Resend after seconds',
  messages_reSend2: 'Resend',
  messages_myFreight: 'I am forwarder',
  messages_websiteNavigation: 'Website Navigation',
  messages_emailExists: 'Email already exists',
  messages_validEmailAddress: 'Please enter a valid email address',
  messages_emailMsg: 'Sent to your mailbox',
  messages_emailMsg2: 'Sent',
  messages_emailMsg3: 'Security verification message, thank you for your registration, please go to the email confirmation verification link_',
  messages_prompt: 'Notice',
  messages_activity: 'Promotions',
  messages_infoNews: 'News',
  messages_omnidistance: 'CARGO TRACKING',
  messages_bsyxWebsite: 'BA-SHI YUEXIN WEBSITE',
  messages_welcomeBSYX: 'Welcome to Ba-SHI YUE XIN E-commerce platform',
  messages_memberAccount: 'Member Account',
  messages_welcomeLand: 'Welcome on board',
  messages_masterNumber: 'MBL',
  messages_branchNumber: 'HBL',
  messages_queryResults: 'Search Result',
  messages_businessNumber: 'PO Nbr',
  messages_latestInfo: 'The latest information',
  messages_releaseTime: 'Release Time',
  messages_viewFullText: 'View the full text',
  messages_emailValidate: 'Please input the valid email address',
  messages_emailValidate2: 'Please enter the email and save',
  messages_newUser: 'New User',
  messages_fastRegister: 'Quick Registration',
  messages_businessModel: 'Open a new booking e-commerce model',
  messages_freeRegistration: 'Free Registration',
  messages_retrievePassword: 'Retrieve Password',
  messages_phoneNotRegistered: 'The mobile phone is not registered',
  messages_verificationCodeError: 'Verify code entered is wrong',
  messages_passwordResetSuccess: 'Password reset successfully',
  messages_passwordFailed: 'Password reset failed',
  messages_phoneNotMatch: 'The mobile phone number is not registered ; The mobile phone number and the user name do not match',
  messages_activateSuccess: 'Activation Successful',
  messages_activationFailed: 'Activation Failed',
  messages_iconCodenotValid: 'Invalid verification code',
  messages_addressInvalid: 'Address is invalid',
  messages_setPassword: 'Set a password',
  messages_setSuccess: 'Set successfully',
  messages_setFailed: 'Set failed',
  messages_minlength: 'Length cannot be less than six',
  messages_notEqualTo: 'New password identical to the original password',
  messages_equalTo: 'Two entered passwords do not match',
  messages_weightPrice: 'Weight price',
  messages_volumePrice: 'Volume price',
  messages_lowestPrice: 'Lowest price',
  messages_aboutET: 'About US.',
  messages_specialOffers: 'Special offers',
  messages_managementPlatform: 'MY E-TOP',
  messages_SOCBoxOffers: 'SOC RATES',
  messages_shippingCompanies: 'Real time notification',
  messages_todaySpecials: 'TODAY\'S SPECIALS',
  messages_ETIntroduction: 'ABOUT US',
  messages_exportFCLRates: 'FCL Export rates',
  messages_userEvaluation: 'User evaluation',
  messages_yitongLanguage: 'E-TOP SHIPPING CO.,LTD',
  messages_languageUrl: 'price_en',
  messages_operationBranch: 'Operation Branch',
  messages_sopNode: 'SOP Nod',
  messages_sopState: 'SOP State',
  messages_unselected: 'Unselected',
  messages_department: 'Department',
  messages_sales: 'Sales',
  messages_ddlOffice: 'Please select the operation branch',
  messages_ddlDepartment: 'Please select the operation department',
  messages_selectOptions: 'Please select OP',
  messages_quantityNumber: 'The pieces must be numerical digit',
  messages_beginEndCompareDate: 'The cut off date can not be earlier than start date',
  messages_shutoutOrder: 'Hello, Could you give a reason for shutting out this shipment? Thanks for your patience_',
  messages_obsoleteReason: 'Cancellation reason',
  messages_selectFile: 'Select file',
  messages_valiCSN: 'No.',
  messages_valiCSN2: 'row,Content length can not exceed',
  messages_valiCSN3: 'character',
  messages_noContactInfo: 'No Contact Information',
  messages_original: 'Original BL',
  messages_telexRelease: 'Surrendered BL',
  messages_seaWaybill: 'SEA WAYBILL',
  messages_uploadGuarantee: 'Download Guarantee Letter template',
  messages_downloadGuarantee: 'upload Guarantee Letter',
  messages_ticket: 'Job',
  messages_billOfLadingMode: 'BL Type',
  messages_volumePriceNumeric: 'Volume Price must be numeric',
  messages_weightPriceNumeric: 'Weight Price must be numeric',
  messages_turbo: 'TURBO INT\'L LOGISTICS',
  messages_notSales: 'Salesman is required',
  messages_portOfTransshipment: 'VIA',
  messages_etopAbout1: 'With a dozen years of working, we have set up good relationship with the carriers_',
  messages_etopAbout2: 'More than 12 years passed, E-top insisting the way of Professional, standard, Scale & Global. In order to take the purpose of letting  our customers  enjoy our best services in each logistics detail. With this purpose, E-top is always put itself at the head forwarding in the industry, offer the best service to all cooperation accounts. And set up several offices at Shanghai/Ningbo/Qingdao/Dalian and planning to set up more offices at main ports in China.',
  messages_etopAddress: 'Address',
  messages_printOrder: 'Print order',
  messages_nomination: 'Nomination Cargo',
  messages_freehand: 'Free hand Cargo',
  messages_overseas: 'Overseas agent',
  messages_typeOfShipment: 'Resource',
  messages_validateFile: 'Upload images size can not exceed 2MB ',
  messages_rejectRemark: 'Please enter the remark',
  messages_productName: 'Item name',
  messages_beforeStarting: 'Before start',
  messages_ongoing: 'Ongoing',
  messages_exception: 'Exception',
  messages_infoNewsCapital: 'NEWS',
  messages_overseasCustomer: 'OVERSEASCUSTOMER',
  messages_overseasCustomerId: 'Please enter your identity ID',
  messages_productIntroduction: 'Product introduction',
  messages_activityCapital: 'PROMOTIONS',
  messages_partner: 'Partner',
  messages_partnerCapital: 'PARTNER',
  messages_myOrderCapital: 'MY ORDER',
  messages_tracingCapital: 'CARGO TRACKING',
  messages_myDeskCapital: 'MY WORKBENCH',
  messsages_rate: 'Rate',
  messages_weekSunday: 'SUN.',
  messages_weekMonday: 'MON.',
  messages_weekTuesday: 'TUE.',
  messages_weekWensday: 'WED.',
  messages_weekThursday: 'THU.',
  messages_weekFriday: 'FRI.',
  messages_weekSaturday: 'SAT.',
  messages_shortWeekSunday: 'SUN.',
  messages_shortWeekMonday: 'MON.',
  messages_shortWeekTuesday: 'TUE.',
  messages_shortWeekWensday: 'WED.',
  messages_shortWeekThursday: 'THU.',
  messages_shortWeekFriday: 'FRI.',
  messages_shortWeekSaturday: 'SAT.',
  messages_freightRates: 'Freight rates explanation',
  messages_promotionsContent: 'Provides you the real-time shipment detailed information_',
  messages_transitTimeLaneCode: 'T/T / VIA / Lane',
  messages_onlineOrder: 'Online order',
  messages_lineOrder: 'Off line order',
  messages_20GPRate: 'Please enter 20GP price',
  messages_40GPRate: 'Please enter 40GP price',
  messages_40HCRate: 'Please enter 40HC price',
  messages_45HCRate: 'Please enter 45HC price',
  messages_40NORRate: 'Please enter 40NOR price',
  messages_deleteRole: 'Please select the role you want to delete',
  messages_deletePermissions: 'Please delete the assigned permissions.',
  messages_remarkReuired: 'Remark is required',
  messages_enterpriseReuired: 'Enterprise abbreviation is required',
  messages_lclConsolidation: 'Professional ocean LCL consolidation service',
  messages_wecanService: 'We-can devote ourselves to develop domistic service network and oversea agent network. We build  close and efficient agency network in South-America, USA, Europe, Mediterranean, Mid-east and Southeast-Asia.',
  messages_wecanDecade: 'We-can\'s advantages in a decade.',
  messages_abundantOversea: 'Abundant Oversea resource',
  messages_safeCarefreeService: 'Safe and carefree service',
  messages_professionalService: 'Professional service network',
  messages_wecanAgentService: 'We build close and efficient agency network in South-America, USA, Europe, Mediterranean, Mid-east and Southeast-Asia.',
  messages_wecanTracking: 'Providing the most comprehensive and real time cargo tracking information to clients.',
  messages_wecanProfessional: 'Dozens of professional line managers are ready to offer better service  and handle all kinds of emergency.',
  messages_seniorSpecialist: 'Professional line specialist',
  messages_professionalService2: 'Integrate whole oversea agency service and combine complete 3PL\'s  service to provide the more valuable service to client.',
  messages_wecanCompany: 'Shanghai We-can International Logistics Co. Ltd.',
  messages_precautions: 'Attention',
  messages_operationInstruction: 'Operation Instruction',
  messages_fileOne: 'A file',
  messages_fileTwo: 'File two',
  messages_warehouseNo: 'Warehouse No',
  messages_transportRe: 'Delivery note No.',
  messages_domesticStatus: 'Domestic Status',
  messages_overseasStatus: 'Overseas Status',
  messages_sorry: 'Sorry',
  messages_relatedInformation: 'related information',
  messages_notFound: 'Can not find',
  messages_aloneTogether: 'Maybe a single person can walk very quickly, however, a team can walk farther',
  messages_serviceFeatures: 'Service Features',
  messages_serviceFeatures2: 'Transparent charges, no hidden cost.',
  messages_ourPartners: 'Our Partners',
  messages_ourPartners2: 'Integration with Top 20+ global container lines',
  messages_aboutUs: 'ABOUT US',
  messages_aboutUs2: 'Know IT best among logitics companies, know logitics best among IT firms.',
  messages_printBill: 'Print Bill of Lading',
  messages_printAMS: 'Print AMS',
  messages_bookingInfo: 'Booking Information',
  messages_validOrNot: 'Valid Or Not',
  messages_destinationAgent: 'Destination\ufffdAgent',
  messages_loadPlanInfo: 'Load Plan Info.',
  messages_sealNumber: 'Seal Number',
  messages_warehouseInfo: 'CFS Info.',
  messages_warehouse: 'Warehouse',
  messages_freightMethod: 'Freight Method',
  messages_goodsSize: 'Goods Size',
  messages_damaged: 'Damaged',
  messages_damagedMemo: 'Damaged Memo',
  messages_picturesOfGoods: 'Pictures\ufffdof Goods',
  messages_inputDone: 'Input Done',
  messages_printDone: 'Print Done',
  messages_inReception: 'In the Reception',
  messages_inspectedNotReleased: 'Inspected & not Released',
  messages_amendmentDocument: 'Amendment Document',
  messages_unknownStatus: 'Unknown Status',
  messages_myTemplate: 'My Template',
  messages_saveTemplate: 'Save Template',
  messages_tools: 'Tools',
  messages_toolsName: 'Tools Name',
  messages_toolsIcon: 'Tools Icon',
  messages_toolsLink: 'Tools Link',
  messages_saveTools: 'Save Links',
  messages_editTools: 'Edit Links',
  messages_fileDownload: 'File Download',
  messages_valiFileName: 'Please input the file name',
  messages_toolsCapital: 'TOOLS',
  messages_emailNotExist: 'The account does not exist',
  messages_mobileFindPassword: 'Mobile authentication',
  messages_ymLogistics: 'Y&M INTERNATIONAL LOGISTICS',
  messages_ymLogistics2: 'FOCUS ON DANGEROUS GOODS LOGISTICS',
  messages_ymStep1: 'CALL, EMAIL, and on-line consulting, we are glad to provide professional service and offer a custermizing solution on dangerous goods logistics.',
  messages_ymStep2: 'Please provide MSDS and dangerous goods packing certification, in case of air transportation, please also provide Certification for Safe Transport of Chemical Goods. For import case, only MSDS is workable. Y&M will provide the best solution for our valued customers.',
  messages_ymStep3: 'After finalized a solution, only email or on line authorized your booking order, Y&M will delivery your goods to world wide customers safely and quickly.',
  messages_specialPriceCapital: 'SPECIAL PRICE',
  messages_practicalLinks: 'Practical Links',
  messages_detailDownload: 'Detail Download',
  messages_amount: 'Amount',
  messages_percentage: 'Percentage',
  messages_permissionToBooking: 'The user does not have permission to booking',
  messages_savePartner: 'Save Partner',
  messages_editPartner: 'Edit Partner',
  messages_myPrAlert: 'My Pr-Alert',
  messages_allBL: 'All B/L',
  messages_FLType: 'F/L Type',
  messages_BLDetails: 'B/L Details',
  messages_AMSDetails: 'AMS Details',
  messages_thumbnail: 'Thumbnail',
  messages_fileInfo: 'File Info.',
  messages_eFiles: 'e-Files',
  messages_businessProfit: 'Business profit',
  messages_uploader: 'Uploader',
  messages_uploadTime: 'Upload Time',
  messages_unitPrice: 'Unit Price',
  messages_money: 'Amount of money',
  messages_myInvoice: 'My Invoice',
  messages_taxpayerNo: 'Taxpayer No.',
  messages_addAndTel: 'Add. & Tel.',
  messages_bankInfoAandTel: 'Bank Info. & Tel.',
  messages_passwordArea: 'Password Area',
  messages_googsOrServices: 'Googs or Services',
  messages_noTaxAmount: 'No Tax Amount',
  messages_taxRate: 'Tax Rate',
  messages_taxAmount: 'Tax Amount',
  messages_inclusiveTotalAmount: 'Tax-inclusive Total Amount (CAP)',
  messages_low: 'LOW',
  messages_salesCompany: 'Sales Company',
  messages_payee: 'Payee',
  messages_reviewer: 'Reviewer',
  messages_builder: 'Builder',
  messages_salesUnit: 'Sales Company (SEAL)',
  messages_myStatement: 'My Billing',
  messages_billingNo: 'BillingNo',
  messages_billAmount: 'Bill Amount',
  messages_sharesLanguage: 'SHARES LOGISTICS (SHANGHAI) CO_,LTD',
  messages_terminalOpendate: 'Opening Date',
  messages_terminalCutoff: 'Cut-off Date',
  messages_realArrivalsDate: 'Real Arrivals Date',
  messages_plateNumber: 'Plate Number',
  messages_vehiclePosition: 'Vehicle Position',
  messages_vehicleTrace: 'Vehicle Trace',
  messages_youthjet: 'YOUTH-JET GLOBLE SUPPLY CHAIN MANAGEMENT CO.,LIMITED',
  messages_longyuan: 'LONGYUAN FORWARDING CO.,LTD.',
  messages_sharesMangement: 'Mangement\ufffdPrinciple',
  messages_sharesMangement2: 'Carefull\ufffdOperation',
  messages_sharesTracking: 'Cargo transport state node tracking query',
  messages_sharesAssessment: 'Assessment',
  messages_sharesAssessment2: 'We\ufffdare\ufffdthe\ufffdnew\ufffdmember\ufffdof\ufffdSino-Japanese\ufffdmarine\ufffdtransportation\ufffd',
  messages_sharesAboutUs: 'The Shares Logistics Co.,Ltd. is the new started company which is like the sun arise in the morning, shining brightly.',
  messages_vehiclePositionMsg: 'Get Vehicle Position failed',
  messages_vehicleTraceMsg: 'Get Vehicle Trace failed',
  messages_overseaWarehouse: 'OVERSEA WAREHOUSE',
  messages_seaImport: 'Sea Import',
  messages_seaExport: 'Sea Export',
  messages_etaDate: 'ETA',
  messages_airImport: 'Air Import',
  messages_airExport: 'Air Export',
  messages_airline: 'Airline',
  messages_flight: 'Flight',
  messages_sailingSchedule: 'Sailing',
  messages_oneMawb: 'One And More Points',
  messages_directAwb: 'Direct AWB',
  messages_onePoints: 'One MAWB and one HAWB',
  messages_portOfLoadingAgency: 'Port of loading agency ',
  messages_ata: 'ATA',
  messages_doDate: 'D/O date',
  messages_dateOfDeliver: 'Date of Deliver',
  messages_dateOfEntry: 'Date of Entry',
  messages_tradeTerms: 'Trade Terms',
  messages_businessUnit: 'Business units',
  messages_esd: 'ESD',
  messages_returnDate: 'Return date',
  messages_modifyBy: 'Modify by',
  messages_modifyDate: 'Modify Date',
  messages_youService: 'EXCELLENT SERVICE FOR EXCELLENT CLIENTS',
  messages_youMangement: 'Service features',
  messages_youMangement2: 'Respect for the differences between global business history, geography, and the habit, to achieve just one-stop of more than one ports, a website, and a set of international procurement and logistics supply chain solutions services.',
  messages_youTracking: 'Door to door of the Global logistics, more than 200 ports and airports, as well as 10 nodes of logistics state tracking, which to achieve the seamless communication with customers',
  messages_youAssessment: 'Partners',
  messages_youAssessment2: 'Have close cooperation between more than 10 major Container lines and more than 20 International Airlines over the world, the effectiveness of the system connection, as well as the trustworthy professional.',
  messages_youAboutUs: 'Youth-Jet, engaged in the global sourcing and logistics supply chain management, is a professional integrated service provider.Our main business includes both export and import of air-freight and sea-freight, as well as Customs declaration, warehousing, international transit and the cross border E-commerce logistics  and so on.',
  messages_valiCarrier: 'Please enter carrier',
  messages_valiAirline: 'Please enter airline',
  messages_sharesIcp: 'HU ICP No. 17023690',
  messages_valiLob: 'Please select business type',
  messages_harborDistrictInfo: 'Harbor District Info.',
  messages_planCallingDate: 'Plan Calling Date',
  messages_planDepartureDate: 'Plan Departure Date',
  messages_actuallyCallingDate: 'Actually Calling Date',
  messages_actuallyDepartureDate: 'Actually Departure Date',
  messages_planBerth: 'Plan Berth',
  messages_actuallyBerth: 'Actually Berth',
  messages_relatedPhotos: 'Related Photos',
  messages_unitConversion: 'Unit Conversion',
  messages_select: 'Select',
  messages_expiryDate: 'Expiry Date',
  messages_merge: 'Merge',
  messages_loading: 'Loading\u2026',
  messages_major: 'Major',
  messages_efficient: 'Efficient',
  messages_visual: 'Visual',
  messages_portOfTransshipment2: 'VIA2',
  messages_frequencys: 'Frequencys',
  messages_secondFrequencys: 'SecondFrequencys',
  messages_thirdFrequencys: 'ThirdFrequencys',
  messages_step: 'N Level',
  messages_advantageIntroduction: 'Advantage Introduction',
  message_aeDetailIntroduction: 'A&E WORLDWIDE Logistics belong ANHAI GROUP, estibilished in 2018. A&E WORLDWIDE registered by Minstry of Communications of PRC. Registered No is SMTC-NV03044. Mainly business power on international Air and Ocean import and export, special OOG cargo handle, etc.',
  messages_projectNumber: 'PONO',
  messages_open: 'Expand',
  messages_retract: 'Collapse',
  messages_inputChineseName: 'Please Input Chinese Name',
  messages_inputEnglishName: 'Please Input English Name',
  messages_inputKeys: 'Please Input Keys',
  messages_findPwStep1: '1、Find Password Type',
  messages_findPwStep2: '2、The verification',
  messages_otherSearch: 'Other Search',
  messages_addUserMessage: 'Normal can operate their own businesses, and admin can view all businesses.',
  messages_belongOffice: 'Company',
  messages_belongDepartment: 'Department',
  messages_loginName: 'Login name',
  messages_account: 'Account',
  messages_supportQuery: 'Support Fuzzy Query',
  messages_addCustomerService: 'Add Customer Service',
  messages_pleaseSelectUser: 'Please Select One User',
  messages_userNameExist: 'User Name already existed',
  messages_userNameRule: 'Pure number and "@ . / \\" is not allow',
  messages_mobileExisted: 'Mobile already existed, please login.',
  messages_wrongMobileNumber: 'Mobile already existed, please login.',
  messages_emailExisted: 'Email already existed, please login',
  messages_wrongEmail: 'Wrong Email, please fill in the correct',
  messages_inputCorrectCode: 'Please enter the correct verification code.',
  messages_inputCorrectPassword: 'Password shall be at least 8 digits and contain upper and lower case letters and numbers.',
  messages_confirmPassword: 'Please confirm password.',
  messages_inputconfirmPassword: 'Please enter your password again.',
  messages_inputCorrectSecondpassword: 'The second password is different, please try again.',
  messages_acceptUserAgreement: 'Please confirm that you accept the user agreement.',
  userName: 'User Name',
  mobile: 'Mobile',
  readAccepted: 'I read and accepted'
}

module.exports = {
  common_QuotationRecord: '报价记录',
  common_ProductService: '产品服务',
  common_LCL: '海运拼箱',
  common_FCL: '海运整箱',
  common_AIR: '空运',
  common_Home: '首页',
  common_Workbench: '我的工作台',
  common_DirectOrder: '直接委托',
  common_Login: '登录',
  common_WelcomeTo: '欢迎登录',
  common_LoginNow: '立即登录',
  common_PleaseEnteryourpassword: '请输入您的密码',
  common_PleaseEnterYourAccount: '请输入您的账号',
  common_ForgotPassword: '忘记密码',
  common_LoginRegister: '登录/注册',
  common_CustomService: '客服电话',
  common_Email: '邮箱',
  common_Language: '语言',

  common_Edit: '编辑',
  common_Details: '查看',
  common_UpdateTime: '更新时间',
  common_Enable: '启用',
  common_Disable: '停用',
  common_Delete: '删除',
  common_Submit: '提交',
  common_Invalid: '无效',
  common_enterplaceofdelivery: '请输入交货地',
  common_enterplaceofreceipt: '请输入收货地',
  common_PlaceofDelivery: '交货地',
  common_PlaceofReceipt: '收货地',
  // 运价列表
  common_Schedule: '班期',
  common_Overbooked: '爆舱',
  common_Space: '舱位',
  common_TransitTime: '航程',
  common_PODTerminal: '交货地-码头',
  common_High: '紧张',
  common_Filter: '其他筛选',
  common_All: '全部',
  common_Whetherdirect: '是否直达',
  common_POLTerminal: '收货地-码头',
  common_Order: '委托',
  common_ValidityPeriod: '有效期',
  common_Normal: '正常',
  common_Direct: '直达',
  common_Transfer: '中转',
  // 注册
  common_Welcometoregister: '欢迎注册',
  common_Pleaseenteryourusername: '请输入您的用户名',
  common_MobileRegistration: '手机号注册',
  common_IhavereadandacceptedtheUserAgreement: '我已阅读并接受',
  common_UserAgreement: '《用户协议》',
  common_Alreadyregisterloginnow: '已注册，立刻登录',
  common_EmailRegistration: '邮箱注册',
  common_Registrationcompletedpleaselogin: '账号注册成功，请登录',
  common_Register: '注册',
  common_Registrationcompleted: '注册完成',
  // 选项
  common_Withdraw: '已撤回',
  common_Returned: '已退关',
  common_ApprovedSH: '已审核',
  common_Submitted: '已提交',
  common_Unsubmitte: '未提交',
  common_Bargaining: '议价',
  common_Approve: '接单',
  common_Reject: '拒绝',
  common_Only1st: '只放第一张',
  common_Round: '轮播',
  common_Menu: '目录',
  common_System: '系统',
  common_Module: '模块',
  common_Page: '页面',
  common_Function: '功能',
  common_Data: '数据',
  common_StateownedEnterprise: '国有企业',
  common_CollectiveOwnership: '集体所有制',
  common_PrivateEnterprise: '私营企业',
  common_JointStockEnterprise: '股份制企业',
  common_LimitedPartnership: '有限合伙企业',
  common_JointVenture: '联营企业',
  common_ForeigninvestedEnterprise: '外商投资企业',
  common_SoleProprietorship: '个人独资企业',
  common_HongKongMacaoandTaiwan: '港、澳、台股份合作企业',
  common_OtherCategories: '其他分类',
  common_Agreement: '同意',
  common_Unifiedsocialcreditcode: '统一社会信用代码',
  common_Default: '默认',
  common_Platform: '平台',
  common_Operator: '运营',
  common_OldCustomers: '老客户',
  common_Newcustomersoftheday: '当日新客户',
  common_RejectedSHBH: '审核驳回',
  common_NA: '无需审核',
  common_RegistrantZCYH: '注册用户',
  common_VisitorNMYH: '匿名用户'
}

import { getToken } from "./auth";

export function isLogin(){
    if(getToken()){
        return true
    }else{
        return false
    }
}

//获取user对象
export function getUserInfo(){
    let user = sessionStorage.getItem('userInfo');
    return JSON.parse(user);
}

// 深拷贝
export function deepClone(obj){
    if (typeof obj !== "object") return obj;
    let cloneObj = obj instanceof Array ? [] : {}
    for (let key in obj) {
        cloneObj[key] = deepClone(obj[key]);
    }
    return cloneObj
}






const TokenKey = 'b2b_token'

export function getToken() {
    return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
    return sessionStorage.setItem(TokenKey, token)
}

export function removeToken(){
    return sessionStorage.removeItem(TokenKey)
}

export function isWeiXin(){
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf('micromessenger') != -1;
    return !isWeixin
}

export function getLanguage(){
    let language
    switch (navigator.language){
        case 'zh-CN':
            language = 'zh'
        break 
        case 'en-US':
            language = 'en'
        break
        default: 
            language = 'zh'
    }
    return language
}
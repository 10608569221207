<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import { getPersonalized } from '@/api/modules/role'
export default {
  data(){
    return {

    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){
      getPersonalized().then(res=>{
        if(res.code === '0000'){
          const result = res.content
          this.$store.dispatch('settings/changeSetting', { key: 'logoList', value: result.logoList })
        }
        
      })
    }
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
body{
  margin: 0;
  padding: 0;
}
#app {
  line-height: normal;
  outline: none;
  font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.body{
  background-color: #24303E;
  width: 100%;
}
.main{
  max-width: 750px;
  margin: 0 auto;
  position: relative;
}
.van-nav-bar{
    height: 80px;
    .van-nav-bar__content{
        height: 80px;
    }
    .van-nav-bar__left{
        .van-icon{
            width: 40px;
            height: 40px;
            color: #000;
            &::before{
                font-size: 40px;
            }
        }
        .van-nav-bar__text{
            color: #1F1F1F;
            font-size: 28px;
        }
    }
}
</style>

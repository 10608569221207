module.exports = {
  common_QuotationRecord: 'Quotation Record',
  common_ProductService: 'Product Service',
  common_LCL: 'LCL',
  common_FCL: 'FCL',
  common_AIR: 'AIR',
  common_Home: 'HOME',
  common_Workbench: 'Workbench',
  common_DirectOrder: 'Direct Order',
  common_Login: 'Login',
  common_WelcomeToxxx: 'Welcome to',
  common_LoginNow: 'Login Now',
  common_PleaseEnteryourpassword: 'Please enter your password',
  common_PleaseEnterYourAccount: 'Please enter your account',
  common_ForgotPassword: 'Forgot Password',
  common_LoginRegister: 'Log in / Register',
  common_CustomService: 'Custom Service',
  common_Email: 'Email',
  common_Language: 'Language',

  common_Edit: 'Edit',
  common_Details: 'Details',
  common_UpdateTime: 'Update Time',
  common_Enable: 'Enable',
  common_Disable: 'Disable',
  common_Delete: 'Delete',
  common_Submit: 'Submit',
  common_Invalid: 'Invalid',
  common_enterplaceofdelivery: 'Please enter a place of delivery.',
  common_enterplaceofreceipt: 'Please enter a place of receipt.',
  common_PlaceofDelivery: 'Place of Delivery',
  common_PlaceofReceipt: 'Place of Receipt',
  // 运价列表
  common_Schedule: 'Schedule',
  common_Overbooked: 'Overbooked',
  common_Space: 'Space',
  common_TransitTime: 'Transit Time',
  common_PODTerminal: 'POD - Terminal',
  common_High: 'High',
  common_Filter: 'Filter',
  common_All: 'All',
  common_Whetherdirect: 'Whether direct',
  common_POLTerminal: 'POL - Terminal',
  common_Order: 'Order',
  common_ValidityPeriod: 'Validity Period',
  common_Normal: 'Normal',
  common_Direct: 'Direct',
  common_Transfer: 'Transfer',
  // 注册
  common_Welcometoregister: 'Welcome to register',
  common_Pleaseenteryourusername: 'Please enter your user name',
  common_MobileRegistration: 'Mobile Registration',
  common_IhavereadandacceptedtheUserAgreement: 'I have read and accepted the ',
  common_UserAgreement: '<User Agreement>',
  common_Alreadyregisterloginnow: 'Already register, login now.',
  common_EmailRegistration: 'Email Registration',
  common_Registrationcompletedpleaselogin: 'Registration completed, please login',
  common_Register: 'Register',
  common_Registrationcompleted: 'Registration completed',
  // 选项
  common_Withdraw: 'Withdraw',
  common_Returned: 'Returned',
  common_ApprovedSH: 'Approved',
  common_Submitted: 'Submitted',
  common_Unsubmitte: 'Unsubmitte',
  common_Bargaining: 'Bargaining',
  common_Approve: 'Approve',
  common_Reject: 'Reject',
  common_Only1st: 'Only 1st',
  common_Round: 'Round',
  common_Menu: 'Menu',
  common_System: 'System',
  common_Module: 'Module',
  common_Page: 'Page',
  common_Function: 'Function',
  common_Data: 'Data',
  common_StateownedEnterprise: 'State-owned Enterprise',
  common_CollectiveOwnership: 'Collective Ownership',
  common_PrivateEnterprise: 'Private Enterprise',
  common_JointStockEnterprise: 'Joint Stock Enterprise',
  common_LimitedPartnership: 'Limited Partnership',
  common_JointVenture: 'Joint Venture',
  common_ForeigninvestedEnterprise: 'Foreign-invested Enterprise',
  common_SoleProprietorship: 'Sole Proprietorship',
  common_HongKongMacaoandTaiwan: 'Hong Kong, Macao and Taiwan Cooperative Stock Enterprise',
  common_OtherCategories: 'Other Categories',
  common_Agreement: 'Approve',
  common_Unifiedsocialcreditcode: 'Unified social credit code',
  common_Default: 'Default',
  common_Platform: 'Platform',
  common_Operator: 'Operator',
  common_OldCustomers: 'Old Customers',
  common_Newcustomersoftheday: 'New customers of the day',
  common_RejectedSHBH: 'Rejected',
  common_NA: 'N/A',
  common_RegistrantZCYH: 'Registrant',
  common_VisitorNMYH: 'Visitor'
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css';
import 'lib-flexible'
import {i18n, vantLocales} from './lang'

// import Vconsole from 'vconsole';
// new Vconsole();

Vue.use(Vant)

Vue.config.productionTip = false

vantLocales(i18n.locale)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

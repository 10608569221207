module.exports = {
  100000: '列{0}的数据太长',
  100001: '用户名为空',
  100002: '密码为空',
  110000: '无法获取企业信息',
  200000: '系统异常，请联系系统管理员',
  210000: '时间过长，请重新登录',
  220000: '未授予相应权限',
  // MemberController',
  300001: '请在一分钟后重试',
  300002: '手机号码已经存在',
  300003: '验证码有误',
  300004: '短信验证码错误',
  300005: '用户名或密码错误',
  300006: '账号不存在',
  300007: '帐号被禁用',
  300008: '登录异常，请联系管理员',
  300009: '邮箱已存在',
  300010: '用户名已存在',
  300011: '用户注册失败',
  310000: '未获取到当前用户的企业信息',
  310001: '企业信息生成失败',
  310002: '创建用户失败，系统中已经存在相同的用户信息',
  310003: '合作伙伴生成失败',
  310004: '企业名称已存在',
  310005: '保存失败，{0}保存出错',
  310008: '请添加联系人信息',
  310009: '抱歉，您注册的身份信息已存在',
  310010: '备注不能为空',
  320000: '未知错误',
  320001: '订单状态异常',
  320002: '该订单为非平台审核，请在Cargoware中审核',
  330000: '合作伙伴关联异常',
  330001: '订单不存在',
  330002: '订单版本不匹配',
  330003: '修改SOP失败',
  340000: '整箱运价广告位信息不存在',
  350000: '删除用户信息出错',
  350001: '无法删除当前登录用户',
  350002: '更新用户状态失败',
  360000: '对单确认出错了',
  360001: '删除角色出错',
  360002: '当前用户没有权限订舱',
  370000: '设置运价级别错误',
  370001: '获取运价级别失败',
  400001: '无法获取对应数据，请联系系统管理员',
  400002: '返回数据不正确，请联系系统管理员',
  400003: '数据已存在，请重新输入',
  400005: '保存模版失败',
  400006: '查询模板失败',
  400007: '删除模板失败',
  // OrderController',
  400010: '保存装箱信息失败'
}

// vue多语言切换
// 根据用户的cookie中的language显示对应的语言，（默认为中文）

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhLocale from './zh_CN'
import enLocale from './en_US'
import { getLanguage } from '@/utils/auth'

Vue.use(VueI18n)

const messages = {
  // en = English
  en: {
    ...enUS,
    ...enLocale,
  },
  // zh = 中文
  zh: {
    ...zhCN,
    ...zhLocale,
  },
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  }
}

export { i18n , vantLocales}

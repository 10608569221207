module.exports = {
  messages_sunday: '七',
  messages_monday: '一',
  messages_tuesday: '二',
  messages_wensday: '三',
  messages_thursday: '四',
  messages_friday: '五',
  messages_saturday: '六',
  messages_productService: '产品服务',
  messages_booking: '订舱服务',
  messages_enterpriseUsers: '欢迎企业用户',
  messages_welcome: '欢迎',
  messages_login: '登录',
  messages_signOut: '退出登录',
  messages_onlineBooking: '直接委托',
  messages_myDesk: '我的工作台',
  messages_inputUnno: '请输入UNNO',
  messages_queryFclPol: '请输入起运港',
  messages_queryFclPod: '请输入目的港',
  messages_newsInfo: '新闻资讯',
  messages_industryDynamics: '行业动态',
  messages_logisticsBusiness: '全方位物流业务',
  messages_tracing: '货物追踪',
  messages_portOfShipment: '起运港',
  messages_portOfDestination: '目的港',
  messages_placeReceipt: '接货地',
  messages_portOfDischarge: '卸货港',
  messages_polCode: '起运港',
  messages_podCode: '目的港',
  messages_exportPrice: '导出运价',
  messages_hotSearch: '热门搜索',
  messages_hamburger: '汉堡',
  messages_busan: '釜山',
  messages_losAngeles: '洛杉矶',
  messages_jobNo: '业务号',
  messages_priceNo: '运价号',
  messages_priceCompare: '运价对比',
  messages_headerBooking: '直接委托',
  messages_headerPrAlert: '我的预报',
  messages_headerTracking: '货物追踪',
  messages_rememberState: '记住登录状态',
  messages_newUserTitle: '您是新用户',
  messages_mailboxCellPhone: '请输入您的手机号、邮箱或账号',
  messages_inputPassword: '请输入您的密码',
  messages_inputPassword2: '请输入确认密码',
  messages_inputPassword3: '两次输入的密码不一样，请重新输入',
  messages_inputPassword4: '密码不能小于6位',
  messages_inputPassword5: '密码必须 6-20位，包含数字，大写字母和小写字母',
  messages_afreshObtainCaptcha: '重新获取验证码',
  messages_smsVerificationCode: '短信验证码',
  messages_getSmsVerificationCode: '获取短信验证码',
  messages_readAgreement: '我已阅读并接受',
  messages_setLoginPassword: '设置登录密码',
  messages_6SMS: '请输入6位短信验证码',
  messages_mobile: '手机号码',
  messages_inputMobile: '请输入手机号码',
  messages_sendSuccess: '发送成功',
  messages_sendError: '发送失败',
  messages_reSend: '秒后重新发送',
  messages_reSend2: '重新发送',
  messages_myFreight: '我是货代',
  messages_websiteNavigation: '网站导航',
  messages_emailExists: '邮箱已经存在',
  messages_validEmailAddress: '请输入有效的邮箱地址',
  messages_emailMsg: '我们已经向您的邮箱',
  messages_emailMsg2: '发送了',
  messages_emailMsg3: '安全验证邮件，感谢您的注册，请您到邮箱里面确认校验链接。',
  messages_prompt: '提示',
  messages_activity: '优惠活动',
  messages_infoNews: '资讯中心',
  messages_omnidistance: '全程跟踪',
  messages_bsyxWebsite: '巴士悦信官网',
  messages_welcomeBSYX: '欢迎访问巴士悦信在线电商平台！',
  messages_memberAccount: '会员帐号',
  messages_welcomeLand: '欢迎登录',
  messages_forgetPassword: '忘记密码',
  messages_masterNumber: '主单号',
  messages_branchNumber: '分单号',
  messages_queryResults: '查询结果',
  messages_businessNumber: '业务参考号',
  messages_latestInfo: '最新动态信息',
  messages_releaseTime: '发布时间',
  messages_viewFullText: '查看全文',
  messages_emailValidate: '请输入有效的电子邮件地址',
  messages_emailValidate2: '请输入邮箱并保存',
  messages_newUser: '新用户',
  messages_fastRegister: '快速注册新用户',
  messages_businessModel: '开启新的订舱电商模式',
  messages_freeRegistration: '立即注册',
  messages_retrievePassword: '找回密码',
  messages_phoneNotRegistered: '手机号码未注册',
  messages_verificationCodeError: '验证码错误',
  messages_passwordSuccess: '密码重置成功',
  messages_passwordFailed: '密码重置失败',
  messages_phoneNotMatch: '手机号码未注册或手机号码与用户名不匹配',
  messages_activateSuccess: '激活成功',
  messages_activationFailed: '激活失败',
  messages_iconCodenotValid: '验证码无效',
  messages_addressInvalid: '地址无效',
  messages_setPassword: '设置密码',
  messages_setSuccess: '设置成功',
  messages_setFailed: '设置失败',
  messages_minlength: '长度不能小于六位',
  messages_notEqualTo: '新密码与原密码相同',
  messages_equalTo: '两次输入密码不相同',
  messages_weightPrice: '重量价',
  messages_volumePrice: '体积价',
  messages_lowestPrice: '最低价',
  messages_aboutET: '关于易通',
  messages_specialOffers: '特价优惠',
  messages_managementPlatform: '管理平台',
  messages_SOCBoxOffers: 'SOC箱优惠',
  messages_shippingCompanies: '实时通知',
  messages_todaySpecials: '今日特价',
  messages_ETIntroduction: '易通介绍',
  messages_exportFCLRates: '整箱出口运价',
  messages_userEvaluation: '用户评价',
  messages_yitongLanguage: '宁波易通船务有限公司 ',
  messages_languageUrl: 'price_zh',
  messages_operationBranch: '操作分公司',
  messages_sopNode: 'SOP节点',
  messages_sopState: 'SOP状态',
  messages_unselected: '未选择',
  messages_department: '部门',
  messages_sales: '销售',
  messages_ddlOffice: '请选择操作分公司',
  messages_ddlDepartment: '请选择操作部门',
  messages_selectOptions: '请选择操作',
  messages_quantityNumber: '件数必须是数字',
  messages_beginEndCompareDate: '截止日期不能早于起始日期',
  messages_shutoutOrder: '您好，麻烦您留下此票货物的退关原因，非常感谢您的耐心配合。',
  messages_obsoleteReason: '作废原因',
  messages_selectFile: '选择文件',
  messages_valiCSN: '第',
  messages_valiCSN2: '行，信息内容长度不能超过',
  messages_valiCSN3: '个字符',
  messages_noContactInfo: '没有联系人信息',
  messages_original: '正本',
  messages_telexRelease: '电放',
  messages_seaWaybill: '见单即放',
  messages_uploadGuarantee: '下载电放保函模板',
  messages_downloadGuarantee: '上传电放保函',
  messages_ticket: '票',
  messages_billOfLadingMode: '提单方式',
  messages_volumePriceNumeric: '体积价格必须是数字',
  messages_weightPriceNumeric: '重量价格必须是数字',
  messages_turbo: '特博国际物流（上海）有限公司',
  messages_notSales: '销售不能为空',
  messages_portOfTransshipment: '中转港',
  messages_etopAbout1: '经过十多年的努力，我们和各家船东有着紧密的合作关系。',
  messages_etopAbout2: 'E-TOP是2003年经中华人民共和国交通部批准成立的无船承运企业（NVOCC），在中华人民共和国交通部注册备案号为MOC-NV00915,主要经营国际集装箱、汽车滚装、重大件、散杂等海洋运输业务，同时是美国联邦海事委员会（FMC）注册成员NO_019755，并在2003年底加入中国船舶代理及无船承运人协会成为会员。',
  messages_etopAddress: '联系地址',
  messages_printOrder: '订单打印',
  messages_nomination: '指定货',
  messages_freehand: '自揽货',
  messages_overseas: '海外代理',
  messages_typeOfShipment: '揽货类型',
  messages_validateFile: '上传图片大小不超过2MB',
  messages_rejectRemark: '请输入备注',
  messages_productName: '品名',
  messages_beforeStarting: '未开始',
  messages_ongoing: '进行中',
  messages_exception: '异常',
  messages_infoNewsCapital: '资讯中心',
  messages_overseasCustomer: '海外客户查询',
  messages_overseasCustomerId: '请输入您的身份id',
  messages_productIntroduction: '产品介绍',
  messages_activityCapital: '优惠活动',
  messages_partner: '合作伙伴',
  messages_partnerCapital: '合作伙伴',
  messages_myOrderCapital: '我的订单',
  messages_tracingCapital: '货物追踪',
  messages_myDeskCapital: '我的工作台',
  messsages_rate: '运价',
  messages_weekSunday: '周日',
  messages_weekMonday: '周一',
  messages_weekTuesday: '周二',
  messages_weekWensday: '周三',
  messages_weekThursday: '周四',
  messages_weekFriday: '周五',
  messages_weekSaturday: '周六',
  messages_shortWeekSunday: '日',
  messages_shortWeekMonday: '一',
  messages_shortWeekTuesday: '二',
  messages_shortWeekWensday: '三',
  messages_shortWeekThursday: '四',
  messages_shortWeekFriday: '五',
  messages_shortWeekSaturday: '六',
  messages_freightRates: '运价说明',
  messages_promotionsContent: '结合第三方完善的SOP节点服务，为客户提供最全面，最及时的货物全程Tracking信息，更有效的服务客户',
  messages_transitTimeLaneCode: '航程 /中转港/航线代码',
  messages_onlineOrder: '线上订单',
  messages_lineOrder: '线下订单',
  messages_20GPRate: '请输入\\20GP价格',
  messages_40GPRate: '请输入\\40GP价格',
  messages_40HCRate: '请输入\\40HC价格',
  messages_45HCRate: '请输入\\45HC价格',
  messages_40NORRate: '请输入\\40NOR价格',
  messages_deleteRole: '请选择要删除的角色',
  messages_deletePermissions: '请将已分配权限删除掉',
  messages_remarkReuired: '备注不能为空',
  messages_enterpriseReuired: '企业简称不能为空',
  messages_lclConsolidation: '专业海运拼箱服务',
  messages_wecanService: '汇恒致力于发展国内服务网络与海外代理网络，在南美、美国、欧洲、地中海、中东印巴、东南亚等几十个国家和地区建立了紧密高效的代理服务网络。',
  messages_wecanDecade: '汇恒十年优势',
  messages_abundantOversea: '海外资源优势 ',
  messages_safeCarefreeService: '安全无忧服务 ',
  messages_professionalService: '专业服务网络',
  messages_wecanAgentService: '南美、美国、欧洲、地中海、中东印巴、东南亚等几十个国家和地区建立了紧密高效的代理服务网络',
  messages_wecanTracking: '为客户提供最全面，最及时的货物全程Tracking信息',
  messages_wecanProfessional: '数十名行业资深航线经理人，对应各种突发事件，全方位立体化保障',
  messages_seniorSpecialist: '资深航线专家',
  messages_professionalService2: '多方整个国际代理服务整合，结合第三方完善的SOP节点服务，为客户提供更高价值服务',
  messages_wecanCompany: '上海汇恒国际物流有限公司',
  messages_precautions: '注意事项',
  messages_operationInstruction: '操作指示',
  messages_fileOne: '文件一',
  messages_fileTwo: '文件二',
  messages_warehouseNo: '进仓编号',
  messages_transportRe: '运输要求备注 ',
  messages_domesticStatus: '国内状态',
  messages_overseasStatus: '国外状态',
  messages_sorry: '对不起',
  messages_relatedInformation: '的相关信息',
  messages_notFound: '未找到',
  messages_aloneTogether: '一个人可以走很快，一群人可以走很远。',
  messages_serviceFeatures: '服务特色',
  messages_serviceFeatures2: '透明价格，杜绝隐性收费，全程无忧。',
  messages_ourPartners: '合作伙伴',
  messages_ourPartners2: '全球前20大集装箱船公司紧密合作，系统对接，值得信赖。',
  messages_aboutUs: '关于我们',
  messages_aboutUs2: '物流公司中最懂得IT价值的，IT公司中最懂物流的。',
  messages_printBill: '打印提单',
  messages_printAMS: '打印AMS',
  messages_bookingInfo: '订舱信息',
  messages_validOrNot: '是否有效',
  messages_destinationAgent: '目的港代理',
  messages_loadPlanInfo: '装箱信息',
  messages_sealNumber: '封号',
  messages_warehouseInfo: '仓库信息',
  messages_warehouse: '仓库',
  messages_freightMethod: '运费条款',
  messages_goodsSize: '货物尺寸',
  messages_damaged: '是否破损',
  messages_damagedMemo: '货物描述',
  messages_picturesOfGoods: '进仓照片',
  messages_inputDone: '已录入',
  messages_printDone: '已拉单',
  messages_inReception: '在前台',
  messages_inspectedNotReleased: '已查未放行',
  messages_amendmentDocument: '改单',
  messages_unknownStatus: '状态未知',
  messages_myTemplate: '我的模板',
  messages_saveTemplate: '保存模板',
  messages_tools: '实用工具',
  messages_toolsName: '名称',
  messages_toolsIcon: '图标',
  messages_toolsLink: '链接',
  messages_saveTools: '保存实用链接',
  messages_editTools: '编辑实用链接',
  messages_fileDownload: '文件下载',
  messages_valiFileName: '请输入文件名称',
  messages_toolsCapital: '实用工具',
  messages_emailNotExist: '该账号不存在',
  messages_mobileFindPassword: '手机号找回',
  messages_ymLogistics: '玉漭国际物流',
  messages_ymLogistics2: '危化品物流专家',
  messages_ymStep1: '电话，EMAIL，在线咨询，我们都会有专业的客服为您服务，第一时间为您解答有关危险品进出口物流的注意事项。',
  messages_ymStep2: '海运出口危险品请提供MSDS和危保证，如果是空运出口还另需提供化工鉴定书；进口则只需提供MSDS即可。我司据此文件给贵司推荐最优物流方案。',
  messages_ymStep3: '最后你只需邮件或者在线委托给我司，您的货物即可安全快捷的送达到全球客户手中。',
  messages_specialPriceCapital: '特价申请',
  messages_practicalLinks: '实用链接',
  messages_detailDownload: '下载清单',
  messages_amount: '金额',
  messages_percentage: '百分比',
  messages_permissionToBooking: '当前用户没有权限订舱',
  messages_savePartner: '保存合作伙伴',
  messages_editPartner: '修改合作伙伴',
  messages_myPrAlert: '我的预报',
  messages_allBL: '所有预报',
  messages_FLType: '整拼类型',
  messages_BLDetails: '提单详情',
  messages_AMSDetails: 'AMS详情',
  messages_thumbnail: '缩略图',
  messages_fileInfo: '文件信息',
  messages_eFiles: '电子文件',
  messages_businessProfit: '业务利润',
  messages_uploader: '上传人',
  messages_uploadTime: '上传时间',
  messages_unitPrice: '单价',
  messages_money: '金额',
  messages_myInvoice: '我的发票',
  messages_taxpayerNo: '纳税人识别号',
  messages_addAndTel: '地址、电话',
  messages_bankInfoAandTel: '开户行及帐号',
  messages_passwordArea: '密码区',
  messages_googsOrServices: '货物或应税劳务名称',
  messages_noTaxAmount: '不含税金额',
  messages_taxRate: '税率',
  messages_taxAmount: '税额',
  messages_inclusiveTotalAmount: '价税合计（合计）',
  messages_low: '（小写）',
  messages_salesCompany: '销货单位',
  messages_payee: '收款人',
  messages_reviewer: '复核',
  messages_builder: '开票人',
  messages_salesUnit: '销货单位（章）',
  messages_myStatement: '我的账单',
  messages_billingNo: '账单号',
  messages_billAmount: '账单金额',
  messages_sharesLanguage: '曦之国际物流（上海）有限公司',
  messages_terminalOpendate: '开港日期',
  messages_terminalCutoff: '截港日期 ',
  messages_realArrivalsDate: '实际进港日期',
  messages_plateNumber: '车牌号',
  messages_vehiclePosition: '车辆位置',
  messages_vehicleTrace: '车辆历史轨迹',
  messages_youthjet: '上海优杰国际货运代理有限公司',
  messages_longyuan: '上海珑瑗国际货物运输代理有限公司',
  messages_sharesMangement: '服务理念',
  messages_sharesMangement2: '我做事您放心',
  messages_sharesTracking: '货物运输状态节点跟踪查询',
  messages_sharesAssessment: '公司定位',
  messages_sharesAssessment2: '中日贸易海上运输服务的新成员',
  messages_sharesAboutUs: '我们是徐徐升起的太阳，散发着美丽的光芒。',
  messages_vehiclePositionMsg: '车辆位置获取失败',
  messages_vehicleTraceMsg: '车辆历史轨迹获取失败',
  messages_overseaWarehouse: '海外仓',
  messages_seaImport: '海运进口',
  messages_seaExport: '海运出口',
  messages_etaDate: '预计到港日',
  messages_airImport: '空运进口',
  messages_airExport: '空运出口',
  messages_airline: '航空公司',
  messages_flight: '航班',
  messages_sailingSchedule: '航期',
  messages_oneMawb: '一主多分 ',
  messages_directAwb: '直单',
  messages_onePoints: '一主一分',
  messages_portOfLoadingAgency: '起运港代理',
  messages_ata: '实际到港日期',
  messages_doDate: '换单日期',
  messages_dateOfDeliver: '提货日期	',
  messages_dateOfEntry: '到库日期',
  messages_tradeTerms: '贸易条款',
  messages_businessUnit: '经营单位',
  messages_esd: '预计提箱日期',
  messages_returnDate: '还箱日期',
  messages_modifyBy: '修改人',
  messages_modifyDate: '修改时间',
  messages_youService: '优质的服务为杰出的客户',
  messages_youMangement: '服务特色',
  messages_youMangement2: '尊重全球商业历史、地域、习惯差异，实现多个港口一站式、一个网站、一整套国际采购与物流供应链解决方案服务。',
  messages_youTracking: '全球物流门-门，200多港口和机场，10个节点货物流状态跟踪，与客户沟通无缝',
  messages_youAssessment: '合作伙伴',
  messages_youAssessment2: '全球主要10多家集装箱班轮公司、20多家国际航空公司紧密合作，系统实效连接，专业值得信赖。',
  messages_youAboutUs: '优杰国际是一家专业从事国际物流供应链管理的综合型服务商，主营业务包括空运进出口运输，国际集装箱进出口，报关、报检、仓储、国际中转运输，跨境电子商务物流平台等业务',
  messages_valiCarrier: '请输入船公司',
  messages_valiAirline: '请输入航空公司',
  messages_sharesIcp: '沪ICP备17023690号',
  messages_valiLob: '请选择业务类型',
  messages_harborDistrictInfo: '港区信息',
  messages_planCallingDate: '计划靠泊日期',
  messages_planDepartureDate: '计划离泊日期',
  messages_actuallyCallingDate: '实际靠泊日期',
  messages_actuallyDepartureDate: '实际离泊日期',
  messages_planBerth: '计划靠泊泊位',
  messages_actuallyBerth: '实际靠泊泊位',
  messages_relatedPhotos: '相关照片',
  messages_unitConversion: '单位换算',
  messages_select: '选择',
  messages_expiryDate: '有效期',
  messages_merge: '合并',
  messages_loading: '正在加载',
  messages_major: '专业',
  messages_efficient: '高效',
  messages_visual: '可视',
  messages_portOfTransshipment2: '中转港二',
  messages_frequencys: '航班时刻',
  messages_secondFrequencys: '二程航班时刻',
  messages_thirdFrequencys: '三程航班时刻',
  messages_step: 'N等级',
  messages_min: 'Min',
  messages_advantageIntroduction: '优势介绍',
  message_aeDetailIntroduction: 'A&E WORLDWIDE 隶属于 ANHAI GROUP, 因业务发展需要于2018年正式成立。中华人民共和国交通部备案号 SMTC-NV03044，主要经营国际空运进出口业务、国际海运进出口业务、超大件货物运输。',
  messages_projectNumber: '项目编号',
  messages_days: ' 船期',
  messages_podFreeTime: ' 目的港免箱期',
  messages_open: '展开',
  messages_retract: '收起',
  messages_inputChineseName: '请输入中文名',
  messages_inputEnglishName: '请输入英文名',
  messages_inputKeys: '请输入关键词',
  messages_findPwStep1: '1、找回密码方式',
  messages_findPwStep2: '2、账号验证',
  messages_otherSearch: '其他筛选条件',
  messages_addUserMessage: 'normal可查看自己创建的订单和用户邮箱与订单联系人邮箱相同的订单;admin可查看本公司全部订单。',
  messages_belongOffice: '所属公司',
  messages_belongDepartment: '所属部门',
  messages_loginName: '登录名',
  messages_account: '账户',
  messages_supportQuery: '支持模糊查询',
  messages_addCustomerService: '添加客服',
  messages_pleaseSelectUser: '请选择一个用户',
  messages_userNameExist: '用户名已存在',
  messages_userNameRule: '用户名不能使用纯数字和"@ . / \\"',
  messages_mobileExisted: '手机已存在，请登录',
  messages_wrongMobileNumber: '手机号码错误，请填写正确手机号',
  messages_emailExisted: 'Email已存在，请登录',
  messages_wrongEmail: 'Email错误，请填写正确Email。',
  messages_inputCorrectCode: '请输入正确的验证码',
  messages_inputCorrectPassword: '密码8-20位数，并包含大小写字母、数字和特殊字符。',
  messages_confirmPassword: '请确认密码',
  messages_inputconfirmPassword: '请再次输入密码',
  messages_inputCorrectSecondpassword: '二次输入密码不相同，请重试。',
  messages_acceptUserAgreement: '请确认您接受用户协议。',
  userName: '用户名',
  mobile: '手机号',
  readAccepted: '我已阅读并接受'
}

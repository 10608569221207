module.exports = {
  saveSuccess: '保存成功。',
  loadingContact: '正在载入联系人信息...',
  process: '正在处理中...',
  orderTimeTip: '抱歉，您选择的运价有变动，请返回运价查询页面，重新查找适合您的航线。',
  refreshChargeBeforeSubmit: '请在提交前刷新费用，确保您的运价是最新的。',
  surchargeMayBeAdjusted: '委托后，附加费根据实际情况可能会有所增减。',
  carrier: '船司',
  orderMessage: '如您已联系销售调整了服务项目或费用，请等待销售通知您完成后，刷新此页面确认正确无误后，再行确认。',
  confimCharge: '确认费用',
  dock: '码头',
  scarcity: '舱位',
  priceDescriptionce: '价格说明',
  perContainer: '每箱',
  perTicket: '每票',
  entrustInstructions: '委托后，附加费根据实际情况可能会有所增减。',
  inputEtdStartDate: 'ETD起始日期不能为空！',
  ts: '暂存',
  pleaseIn: '请在',
  minute: '分钟',
  second: '秒',
  within: '内',
  complete: '完成',
  refresh: '刷新',
  oceanFreight: '海运费',
  returnAndReconsider: '返回，重新考虑船期',
  bookingThe: '订舱摘要',
  telphone: '固定电话',
  uploadBookingFile: '托书上传',
  orderRemark: '订单备注',
  sureOrder: '确定下单',
  reservationNumber: '预定单号',
  advanceOrderState: '预订单状态',
  adjustment: '调整',
  searchNo: '查询单号',
  date: '时间',
  clickOpen: '点此展开',
  clickClose: '点此收起',
  positiveOperation: '正操作',
  preSubmission: '预提交',
  preEtd: '预定开航日',
  chargeUnit: '计费单位',
  allRecord: '共',
  strip: '条',
  bookingFile: '托书附件',
  noFiles: '无附件',
  approvalResult: '审批结果',
  branchOf: '归属分公司',
  opDepartment: '操作部门',
  approvalMessage: '审批说明',
  box: '箱',
  adjustmentRate: '调整费率',
  reserveMessage: '预定信息',
  adjustmentRemark: '调价备注',
  uploadBooking1: '请上传',
  uploadBooking3: '及其他相关资质材料',
  support: '支持',
  validateFile50: '总文件大小不能超过50MB',
  orderComplete1: '下单成功，等待确认',
  orderComplete2: '已下单成功，请等待 上海巴士悦信物流发展有限公司 确认接单。您可在工作台->订舱单中找到此票订单，或点击 ',
  orderComplete3: ' 将订单号粘贴到查询界面进行查找。',
  tenantNamePlaceholder: '在后续账单和开票上体现的公司名称',
  contactNamePlaceholder: '受托方将和您指定的联系人保持沟通',
  contactPhonePlaceholder: '用于紧急联系的手机',
  contactMobilePlaceholder: '联系人的公司座机，日常沟通用',
  contactEmailPlaceholder: '用于联络通知的邮箱',
  contactQqPlaceholder: '请先确认受托方是否支持QQ沟通',
  approveRemark: '请输入审批不通过原因',
  inputJobNoMBL: '请输入Job No或MB/L No',
  adjustmentPerson: '调价人'
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLogin } from '../utils/common'
import { Notify } from 'vant';

// 需要登录才能进入的页面
const needLoginRouterGuard = (to, from, next) => {
  if (isLogin() === true) {
    next()
  } else {
    Notify('请先登录！')
    router.push({
      path: '/login'
    })
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/home/index.vue'),
    meta: {
      title: '首页',
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/search/index.vue'),
    meta: {
      title: '搜索',
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/list/index.vue'),
    beforeEnter: needLoginRouterGuard,
    meta: {
      title: '运价列表',
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/detail/index.vue'),
    beforeEnter: needLoginRouterGuard,
    meta: {
      title: '运价详情',
    }
  },
  {
    path: '/compare',
    name: 'compare',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/compare/index.vue'),
    beforeEnter: needLoginRouterGuard,
    meta: {
      title: '运价对比',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/login/login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/login/register.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/login/reset.vue'),
    meta: {
      title: '重置密码',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

module.exports = {
  role_roleName: 'Role Name',
  role_role: 'Role',
  role_Copy: 'Copy',
  role_Scope: 'Scope',
  role_OperatorRoleList: 'Operator Role List',
  role_Add: 'Add',
  role_PresetAuthority: 'Preset Authority',
  role_Visitor: 'Visitor',
  role_PersonalUser: 'Personal User',
  role_EnterpriseOperation: 'Enterprise Operation',
  role_EnterpriseAdministrator: 'Enterprise Administrator',
  role_EnterpriseCS: 'Enterprise CS',
  role_EnterpriseSales: 'Enterprise Sales',
  role_AllData: 'AllData',
  role_OperationAdministrator: 'Operation Administrator',
  role_OperationCS: 'Operation CS',
  role_SelfData: 'SelfData',
  // 运营账号
  role_Edit: 'Edit',
  role_Pleaseconfirmwhethertoenablethisaccount: 'Please confirm whether to enable this account?',
  role_Pleaseconfirmwhethertodisablethisaccount: 'Please confirm whether to disable this account?',
  role_enterthemobileoftheaccountowner: '	Please enter the mobile of the account owner',
  role_AddAccount: '	Add'
}

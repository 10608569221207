module.exports = {
  saveSuccess: 'Save successfully.',
  loadingContact: 'Loading contact information...',
  process: 'Processing...',
  orderTimeTip: 'Sorry, the route freight you selected has changed. Please return to the freight query page to find the route suitable for you.',
  refreshChargeBeforeSubmit: 'Please refresh the charges before submitting to make sure your rates are up to date.',
  surchargeMayBeAdjusted: 'After entrustment, the surcharge may be adjusted according to the actual situation.',
  carrier: 'Carrier',
  orderMessage: 'If you have contacted the sales to adjust fees, please wait for completion notice and refresh fees list, to confirm that it is correct.',
  confimCharge: 'confirm cost',
  dock: 'Dock',
  scarcity: 'Scarcity',
  priceDescriptionce: 'Price Description',
  perContainer: 'Per Container',
  perTicket: 'Per Ticket',
  entrustInstructions: 'After order, the surcharge may be increased or decreased according to the actual situation.',
  inputEtdStartDate: 'Please Input ETD Begin Date',
  ts: 'temporary storage',
  pleaseIn: 'Please In',
  minute: 'Minute',
  second: 'Second',
  within: 'within',
  complete: 'complete',
  refresh: 'refresh',
  oceanFreight: 'Ocean Freight',
  returnAndReconsider: 'Return and reconsider the date',
  bookingThe: 'Booking Summary',
  telphone: 'Telphone',
  uploadBookingFile: 'Upload Booking Files',
  orderRemark: 'Remarks',
  sureOrder: 'Determine the order',
  reservationNumber: 'Reservation number',
  advanceOrderState: 'Advance Order State',
  adjustment: 'adjustment',
  searchNo: 'Search No',
  date: 'Date',
  clickOpen: 'Click here to open',
  clickClose: 'Click here to close',
  positiveOperation: 'Positive operation',
  preSubmission: 'Pre-Submit',
  reviewState: 'Review State',
  preEtd: 'Pre-ETD',
  chargeUnit: 'ChargeUnit',
  allRecord: 'all Record',
  strip: 'strip',
  bookingFile: 'Booking File',
  noFiles: 'No Files',
  approvalResult: 'Approval Result',
  branchOf: 'Owned branch',
  opDepartment: 'Operation department',
  approvalMessage: 'Approval Message',
  box: 'box',
  adjustmentRate: 'Adjust the rates',
  reserveMessage: 'reserve message',
  adjustmentRemark: 'Adjust Rate Remark',
  uploadBooking1: 'Please upload',
  uploadBooking3: 'and other relevant qualification materials.',
  support: 'Support',
  validateFile50: 'Upload images size can not exceed 50MB ',
  orderComplete1: 'Order is successful, waiting for confirmation.',
  orderComplete2: 'has been ordered, please wait for the confirmation of 上海巴士悦信物流发展有限公司. You can find this order in Workbench > Order List, or click ',
  orderComplete3: 'to paste the order number into the query box.',
  tenantNamePlaceholder: 'Displayed on billing and Invoice.',
  contactNamePlaceholder: 'Who will contact the agent.',
  contactPhonePlaceholder: 'Mobile for emergency contact.',
  contactMobilePlaceholder: 'General contact number.',
  contactEmailPlaceholder: 'Contact email.',
  contactQqPlaceholder: 'Please confirm whether the agent supports QQ.',
  approveRemark: 'Please type the reason for disapproval.',
  inputJobNoMBL: 'Please type in Job No. or MB/L No.',
  adjustmentPerson: 'adjustment Person'
}

module.exports = {
  spa_SKURate: 'SKU价格',
  spa_PackageQuantity: '包装数量',
  spa_ProductInformation: '产品信息',
  spa_SuccessfullySubmittedApplication: '成功提交申请',
  spa_Applicationsubmittedsuccessfully1: '成功提交申请！',
  spa_Applicationsubmittedsuccessfully2: '恭喜！您的特价申请申请已成功提交，编号为xxxxxxx',
  spa_Applicationsubmittedsuccessfully3: '3秒后将自动返回运价列表',
  spa_FreightList: '运价查询',
  spa_ShippingScheduleInformation: '船期信息',
  spa_No: '否',
  spa_Yes: '是',
  spa_LaneCode: '航线代码',
  spa_Notes: '接单说明',
  spa_ContactPerson: '联系人',
  spa_GrossWeight: '毛重',
  spa_Commodity: '品名（货品类别）',
  spa_ApplicationRemarks: '申请备注',
  spa_ApplicationNo: '申请号',
  spa_RequestRate: '申请价格',
  spa_ReviewRate: '审核价格',
  spa_Rejected: '审核拒绝',
  spa_Approved: '审核通过',
  spa_DefaultBusinessContact: '是否为业务联络人',
  spa_AllSpecialRateRequests: '所有特价申请',
  spa_SpecialRate: '特价申请',
  spa_SpecialRateApplicationPrice: '特价申请价格',
  spa_Tips: '提示：价格存在调整',
  spa_Volume: '体积',
  spa_Add: '添加联系人',
  spa_Order: '委托',
  spa_ContainersDescription: '箱型箱量',
  spa_Remove: '移除',
  spa_EnglishDescription: '英文品名',
  spa_OriginalRateReference: '原价参考',
  spa_TransportationMode: '运输条款',
  spa_NativeDescription: '中文品名',
  spa_Notifier: '通知人'
}

module.exports = {
  order_SOP: 'SOP节点',
  order_Remarks: '备注',
  order_Tracking: '订单跟踪',
  order_Time: '时间',
  order_Status: '状态',
  order_JobNo: 'CW业务编号',
  order_Details: '查看详情',
  order_Withdraw: '撤回',
  order_Carrier: '船司',
  order_Pending: '待审核',
  order_Today: '当天',
  order_OrderNumber: '订单编号',
  order_OrderType: '订单类型',
  order_OrderList: '订单列表',
  order_Search: '检索',
  order_Customer: '客户',
  order_Pleaseconfirmtowithdrawthisspecialapplication: '请确认是否撤回该条申请吗？',
  order_Reviewer: '审核人',
  order_AllOrders: '所有订单',
  order_Submitter: '提交人',
  order_Thismonth: '未来四周',
  order_BookingDate: '下单时间',
  order_NextWeek: '下周',
  order_Approved: '已接单',
  order_Rejected: '已拒绝',
  order_ETD: '预计开航日期',
  order_Reapply: '重新申请',
  order_Reset: '重置',
  order_Customdisplaycolumns: '自定义显示列',
  order_OperationDepartment: '操作部门',
  order_OperationBranch: '操作分公司',
  order_Operator: '操作人员',
  order_Vessel: '船名',
  order_OrderDetails: '订单详情',
  order_CostList: '费用清单',
  order_Port: '港区',
  order_Voyage: '航次',
  order_CommodityInformation: '货物信息',
  order_SICutOff: '截单时间',
  order_CYCutoff: '截港时间',
  order_ClosingTime: '截关时间',
  order_CustomerService: '客服',
  order_ContactInformation: '联系人信息',
  order_BookingInformation: '配舱信息',
  order_Review: '审核',
  order_Reason: '审核备注',
  order_Auditinformation: '审核信息',
  order_BillofladingNo: '提单号',
  order_Billofladinginformation: '提单信息',
  order_entrustRemarks: '委托备注',
  order_Document: '文件附件',
  order_BoxInformation: '箱型信息',
  order_SalesDepartment: '销售部门',
  order_SalesBranch: '销售分公司',
  order_Salesman: '销售人员',
  order_SerialNumber: '序号',
  // 委托下单
  order_Forgeneralchemicals: '一般化工品需上传属地鉴定证书（如上海港起运需要上传上化院鉴定证书），危险品需要上传危报证，所有化工品均需附带MSDS',
  order_PackageCategory: '包装类型',
  order_Template: '标准托书模板下载',
  order_BookingDetails: '查看托书文件',
  order_SecondNotifyParty: '第二通知人',
  order_OrderNoXXX1: '订单号：',
  order_OrderNoXXX2: '，已下单成功，请等待确认接单。您可在工作台',
  order_OrderNoXXX3: '->订舱单中找到此票订单，或点击 >',
  order_OrderNoXXX4: '< 将订单号粘贴到查询界面进行查找。',
  order_Shipper: '发货人',
  order_Surcharge: '附加费',
  order_OceanFreight: '海运费',
  order_Total: '合计',
  order_BasicInformation: '基本信息',
  order_MarksNumbers: '唛头',
  order_Clear: '清空',
  order_PleasedragfileofShippingOrderXXX: '请将 委托书 文件拖拽至此处上传需要识别的文件，或点击',
  order_RecommendPNGJPGXXX: '支持.png, .jpg, .gif, .tif, .doc, .docx, .xls, .xlsx, .pdf',
  order_Pleaseuploadrelevantdocuments: '请上传相关文件材料',
  order_ShipperConsigneeNotifyParty: '收发通信息',
  order_Consignee: '收货人',
  order_FillinanycontentyouthinknecessaryXXX: '填写任何您认为有必要向委托方说明的内容，以便受托方在操作和沟通时能正确理解您的诉求。',
  order_NotifyParty: '通知人',
  order_SAMEASCONSIGNEE: '同“收货人”信息',
  order_Booking: '托书',
  order_TSBookingInformation: '托书信息',
  order_Fileupload: '文件上传',
  order_OrdersubmittedsuccefullyWaitingforconfirmation: '下单成功，等待确认',
  order_RelevantDocuments: '相关文件',
  order_Subtotal: '小计',
  order_Choosefrom: '选择',
  order_BusniessRefNo: '业务参考号',
  order_BusinessRelevantInformation: '业务关联信息',
  order_Bargaining: '在线议价',
  order_RecommendPDFRarZip: '支持.png, .jpg, .txt, .doc, .docx, .xls, .xlsx, .pdf, .rar, .zip',
  order_ToOrder: '指示提单',
  order_RedoUploadRedoRecognition: '重新上传，重新识别',
  order_Thetotalsizeoffilesarenomorethan50MB: '总文件大小不超过50MB'

}


module.exports = {
  personal_Mobile: 'Mobile',
  personal_BindMobile: 'Bind Mobile',
  personal_BindEmail: 'Bind Email',
  personal_BindPersonalMobile: 'Bind Personal Mobile',
  personal_BindPersonalEmail: 'Bind Personal Email',
  personal_Unbind: 'Unbind',
  personal_SetMobile: 'Set Mobile',
  personal_SetEmail: 'Set Email',
  personal_Save: 'Save',
  personal_Back: 'Back',
  personal_Personal: 'Personal',
  personal_Profile: 'Profile',
  personal_Ascription: 'Ascription',
  personal_GetVerificationCode: 'Get Verification Code',
  personal_BonusPoints: 'Bonus Points',
  personal_Type: 'Type',
  personal_Offline: 'Off-line',
  personal_Password: 'Password',
  personal_ConfirmPassword: 'Confirm Password',
  personal_Passwordmodifysuccessfullypleaseloginwithnewpassword: 'Password modify successfully, please login with new password.',
  personal_NickName: 'Nick Name',
  personal_Switch: 'Switch',
  personal_ConfirmUnbindAccountBefore: 'Please confirm whether to unbind the account',
  personal_ConfirmUnbindAccountAfter: '?',
  personal_ConfirmActivateAsYourWorkaccountBefore: 'Please confirm whether to activate',
  personal_ConfirmActivateAsYourWorkaccountAfter: 'as your work account?',
  personal_Pleaseenteryourmobile: 'Please enter your mobile',
  personal_Pleaseenteryournewmobile: 'Please enter your new mobile',
  personal_Pleaseenteryournewemail: 'Please enter your new email',
  personal_Pleaseenteryouremail: 'Please enter your email',
  personal_Pleaseentertheenterpriseinvitationcode: 'Please enter the enterprise invitation code',
  personal_Pleaseenterthecurrentpassword: 'Please enter the current password',
  personal_Pleaseenterthenewpassword: 'Please enter the new password',
  personal_Pleaseenterthepasswordagain: 'Please enter the password again',
  personal_Pleaseenterverificationcode: 'Please enter verification code',
  personal_OK: 'OK',
  personal_Comfirm: 'Comfirm',
  personal_Cancel: 'Cancel',
  personal_Prev: 'Prev',
  personal_Next: 'Next',
  personal_SetResults: 'Set Results',
  personal_Bindmobilesuccessfully: 'Bind mobile successfully.',
  personal_EnterEnterpriseInvitationCode: 'Enter Enterprise Invitation Code',
  personal_NewPassword: 'New Password',
  personal_NewMobile: 'New Mobile',
  personal_NewEmail: 'New Email',
  personal_Edit: 'Edit',
  personal_ModifyPassword: 'Modify Password',
  personal_VerifyMobile: 'Verify Mobile',
  personal_VerifyEmail: 'Verify Email',
  personal_InvitationCode: 'Invitation Code',
  personal_UserName: 'User Name',
  personal_Bindemailsuccessfully: 'Bind email successfully.',
  personal_Valid: 'Valid',
  personal_CurrentPassword: 'Current Password',
  personal_Online: 'On-line',
  personal_Account: 'Account',
  personal_Relogin: 'Relogin',
  // 个性化配置
  personal_BackgroundPicture: 'Background Picture',
  personal_BackgroundPicturePlaySetting: 'Background Picture Play Setting',
  personal_FloorPrice: 'Floor Price',
  personal_ConnectFMS: 'Connect FMS',
  personal_ServiceAgreement: 'Service Agreement',
  personal_OperatorPersonalization: 'Operator Personalization',
  personal_FCLDefaultDisplay: 'FCL Default Display',
  personal_DefaultOrderDisplay: 'Default Order Display',
  personal_Isbargainingallowed: 'Is bargaining allowed',
  personal_Picturelength45: 'Picture length and width: 105 * 45',
  personal_Picturelength1080: 'Picture length and width: 1920 * 1080',
  personal_OnlyJPGPNGnomorethan1m: 'Only JPG and PNG are supported, and the size of each is no more than 1m.',
  personal_SiteName: 'Site Name',
  personal_Domain: 'Domain',
  personal_Systemallowedupto3pictures: 'System allowed up to 3 background pictures.',
  personal_BusinessFlow: 'Business Flow',
  personal_PrivacyAgreement: 'Privacy Agreement',
  personal_ThemeColor: 'Theme Color',
  // 忘记密码
  personal_LoginPassword: 'Login Password',
  personal_Passwordresetsuccessfullypleaseloginwithnewpassword: 'Password reset successfully, please login with new password.',
  personal_Pleaseentertheloginpassword: 'Please enter the login password',
  personal_MobileRetrieve: 'Mobile Retrieve',
  personal_LoginNow: 'Login Now',
  personal_VerificationCode: 'Verification Code',
  personal_EmailRetrieve: 'Email Retrieve',
  personal_RetrievalMethod: 'Retrieval Method',
  personal_RetrieveResults: 'Retrieve Results',
  personal_ResetPassword: 'Reset Password'

}

module.exports = {
  role_roleName: '角色名称',
  role_role: '角色',
  role_Copy: '复制',
  role_Scope: '节点授权范围(类型)',
  role_OperatorRoleList: '权限角色',
  role_Add: '新增权限',
  role_PresetAuthority: '预置权限',
  role_Visitor: '访客',
  role_PersonalUser: '个人用户',
  role_EnterpriseOperation: '企业操作',
  role_EnterpriseAdministrator: '企业管理员',
  role_EnterpriseCS: '企业客服',
  role_EnterpriseSales: '企业销售',
  role_AllData: '全部业务',
  role_OperationAdministrator: '运营管理员',
  role_OperationCS: '运营客服',
  role_SelfData: '自有业务',
  // 运营账号
  role_Edit: '编辑账号',
  role_Pleaseconfirmwhethertoenablethisaccount: '请确认是否启用该账号？',
  role_Pleaseconfirmwhethertodisablethisaccount: '请确认是否停用该账号？',
  role_enterthemobileoftheaccountowner: '请输入账号所有人的手机',
  role_AddAccount: '新增账号'
}

/**
 * @see 该文件主要是接口配置文件
 * created in 2021.12.15
 * 注意: 接口的的配置请注意写明接口的用途注释
 */
 import axios from 'axios'
 import { Dialog, Notify } from 'vant'
 import store from '@/store'
 import { getToken } from '@/utils/auth'
 
 // create an axios instance
 // 创建一个axios实例.包含一些基础的配置
const service = axios.create({
  baseURL: '/', //
  timeout: 40000 // request timeout
})
 
 // request interceptor 请求拦截
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['language'] = store.getters.language === 'en' ? 'en_US' : 'zh_CN'
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
 
// response interceptor 返回拦截
service.interceptors.response.use(
  /**
  * If you want to get http information such as headers or status
  * Please return  response => response
  */

  /**
  * Determine the request status by custom code
  * Here is just an example
  * You can also judge the status by HTTP Status Code
  */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
  if (res.code !== '0000') {
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === '401' || res.code === 50012 || res.code === 50014) {
      // to re-login
      Dialog.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
      }
      Notify({
      message: res.message || 'Error',
      type: 'danger',
      duration: 5 * 1000
    })
      return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return res
  }
  },
  error => {
  console.log('err  ' + error.response.data.message) // for debug
  if (error.response.status === 401) {
    store.dispatch('user/resetToken').then(() => {
      location.reload()
    })
  }else{
    Notify({
      message: '服务繁忙，请稍后再试！',
      type: 'danger',
      duration: 5 * 1000
    })
  }
    return Promise.reject(error)
  }
)
 
export default service
 
 
 